<template>
  <bb-modal :visible="visible" @close="$emit('close')">
    <template slot="heading">
      {{ title }}
    </template>
    <p v-show="message" class="f-small f-gotham-book m-b-20 color-gray-80">
      {{ message }}
    </p>
    <slot name="content"></slot>
    <bb-row class="narrow p-l-10 p-r-10">
      <bb-col col="12">
        <bb-button
          id="noButton"
          :inverted="noInverted"
          display="block"
          :color="noColor"
          @click="noClick"
        >
          {{ lblNo }}
        </bb-button>
      </bb-col>
      <bb-col col="12">
        <bb-button
          id="yesButton"
          :inverted="yesInverted"
          display="block"
          :color="yesColor"
          :disabled="yesDisabled || disabledAfterClick"
          @click="yesClick"
        >
          {{ lblYes }}
        </bb-button>
      </bb-col>
    </bb-row>
  </bb-modal>
</template>
<script>
  import { BbRow,
           BbCol,
           BbButton,
           BbModal } from '@bigbank/interface-components';
  import i18n from '@/utils/common/i18n';

  export default {
    name: 'confirmation-dialog',
    components: {
      BbRow,
      BbCol,
      BbButton,
      BbModal,
    },
    props: {
      title: {
        type: String,
      },
      message: {
        type: String,
      },
      visible: {
        type: Boolean,
      },
      lblNo: {
        type: String,
        default: () => i18n.tc('common.no'),
      },
      lblYes: {
        type: String,
        default: () => i18n.tc('common.yes'),
      },
      noInverted: {
        type: Boolean,
        default: false,
      },
      yesInverted: {
        type: Boolean,
        default: false,
      },
      noColor: {
        type: String,
        default: 'red',
      },
      yesColor: {
        type: String,
        default: 'mint',
      },
      yesDisabled: {
        type: Boolean,
      },
    },
    data () {
      return {
        disabledAfterClick: false,
      };
    },
    watch: {
      visible (val) {
        if (!val) {
          this.disabledAfterClick = false;
        }
      },
    },
    methods: {
      yesClick () {
        this.$emit('yesClick');
        this.disabledAfterClick = true;
      },
      noClick () {
        this.$emit('noClick');
      },
    },
  };
</script>
