<template>
  <bb-modal
    :visible="visible"
    flavor="card"
    @close="close"
  >
    <template slot="heading">
      Select country
    </template>
    <bb-form
      static-submit
      ticket-buttons
      submit-text="Continue"
      :disabled="!selectedDomain"
      @submit="select"
    >
      <bb-radio
        v-if="countries"
        id="countryRadio"
        v-model="selectedDomain"
        name="selectedDomain"
        :options="countries"
        block
      />
    </bb-form>
  </bb-modal>
</template>

<script>
  import { BbModal, BbForm, BbRadio } from '@bigbank/interface-components';

  export default {
    name: 'country-select',
    components: {
      BbModal,
      BbForm,
      BbRadio,
    },
    props: {
      countries: {
        type: Array,
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        selectedDomain: null,
      };
    },
    methods: {
      select () {
        this.$emit('country-selected', this.selectedDomain);
      },
      close () {
        this.$emit('close');
      },
    },
  };
</script>
