<template>
  <bb-modal
    :class="{ 'aml-video': true }"
    visible
    size="x3"
    :hide-close="deadlinePassed"
    full-mobile
    static-overlay
    @close="$emit('close')"
  >
    <template slot="heading">
      {{ $t('amlVideoModal.title') }}
    </template>
    <bb-row justify="center">
      <div v-if="!deadlinePassed && !showVideo" class="align-center">
        <p>{{ `${$t('amlVideoModal.trainingWillExpire')} ${dateOfExpiration}. ${$t('amlVideoModal.pleaseWatch')}` }}</p>
        <bb-button
          id="showVideoButton"
          class="m-t-20"
          @click="showVideo = true"
        >
          {{ $t('amlVideoModal.showVideo') }}
        </bb-button>
      </div>
      <bb-col col="auto">
        <video
          v-if="showAmlVideo"
          class="video"
          controls
          autoplay
          width="100%"
          :poster="posterUrl"
          @ended="ended"
        >
          <source :src="getSource">
        </video>
      </bb-col>
    </bb-row>
  </bb-modal>
</template>
<script>
  import { BbRow,
           BbCol,
           BbModal,
           BbButton } from '@bigbank/interface-components';
  import dayjs from 'dayjs';

  export default {
    name: 'aml-video-modal',
    components: {
      BbRow,
      BbCol,
      BbModal,
      BbButton,
    },
    data() {
      return {
        showVideo: false,
      };
    },
    computed: {
      posterUrl() {
        return require('../../assets/img/aml_poster.jpg');
      },
      getSource() {
        return this.$isFi() ? '/aml_video_finland.mp4' : '/aml_video_estonia.mp4';
      },
      dateOfExpiration() {
        return this.$userData().amlTrainingDate
          ? dayjs(this.$userData().amlTrainingDate).add(365, 'days').format('DD.MM.YYYY')
          : '';
      },
      deadlinePassed() {
        return this.$userData().amlTrainingDate
          ? Math.abs(dayjs(this.$userData().amlTrainingDate).diff(new Date, 'day')) >= 365
          : true;
      },
      showAmlVideo () {
        return this.deadlinePassed || this.showVideo;
      },
    },
    methods: {
      ended () {
        this.$emit('videoWatched');
      },
    },
  };
</script>
<style lang="scss">
 .aml-video {
   .bb-modal {
     &__title {
       text-align: center;
     }

     .video {
       pointer-events: none;
     }
   }
 }
</style>
