import dayjs from 'dayjs';
import moment from 'moment-mini';

const personalCodeNewFormatValidator = {
  regex: /^32\d{4}-\d{5}$/,
};

export const personalCodeOldFormatValidator = {
  regex: /^\d{6}-?[012]\d{4}$/,
};

function validatePersonalCodeLatvia (value) {
  if (isNewFormatPersonalCode(value)) {
    if (!personalCodeNewFormatValidator.regex.test(value)) {
      return false;
    }
  } else if (!personalCodeOldFormatValidator.regex.test(value) || !isValidDateFromOldPersonalCode(value)) {

    return false;
  }

  return isValidChecksum(value);
}

export function validateAgeLatvia (value) {
  const oldPersonalIdentityCode = +value.substring(0, 2) < 32;
  if (oldPersonalIdentityCode) {
    const century = parseInt(value.substring(7, 8));

    if (century < 0 || century > 2) {
      return false;
    }
    const day = value.substring(0, 2);
    const month = value.substring(2, 4);
    let year = 1800 + (century * 100);
    year += parseInt(value.substring(4, 6));

    const years = moment().diff(moment(day + month + year, 'DDMMYYYY'), 'years');
    const months = moment().diff(moment(day + month + year, 'DDMMYYYY'), 'months') % 12;

    return years >= 19 && (years <= 73 && months <= 11);
  }

  return true;
}

function isValidDateFromOldPersonalCode (value) {
  const century = parseInt(value.substring(7, 8));
  if (century < 0 || century > 2) {
    return false;
  }

  const day = value.substring(0, 2);
  const month = value.substring(2, 4);
  let year = 1800 + (century * 100);
  year += parseInt(value.substring(4, 6));

  if (day < 1 || day > 31 || month < 1 || month > 12) {
    return false;
  }

  return dayjs(new Date(year, month, day)).isValid();
}

function isNewFormatPersonalCode (value) {
  return value && value.startsWith('32');
}

function isValidChecksum (personalCode) {
  const personalCodeWithoutDash = personalCode.replace('-', '');
  const checkSumDigits = [
    1,
    6,
    3,
    7,
    9,
    10,
    5,
    8,
    4,
    2,
  ];
  let checkSum = 0;

  for (const index in checkSumDigits) {
    checkSum += ((+personalCodeWithoutDash.charAt(index)) * (+checkSumDigits[index]));
  }
  checkSum = (1 - checkSum) % 11;
  checkSum += (checkSum <= -1) ? 11 : 0;

  return checkSum % 10 === Number(personalCodeWithoutDash[10]);
}

export {
  validatePersonalCodeLatvia,
};
