<template>
  <bb-container class="modal-form-wrapper">
    <div class="align-left" style="padding: 3rem;">
      <bb-modal
        visible
        :size="thankYouView ? 'x1' : size"
        :center-vertically="centerVertically"
        :hide-close="disableClose"
        :static-overlay="disableClose || !isFullyVisible"
        :class="!visible && 'modal-disabled'"
        @close="close"
      >
        <template slot="heading">
          {{ heading }}
        </template>
        <bb-row v-if="thankYouView" justify="center">
          <bb-col col="24" class="align-center m-t-30 p-b-30">
            <bb-icon name="ui-check-circle-outline" size="128" fill="mint" />
          </bb-col>
          <bb-col col="24" class="align-center m-t-30">
            <p class="f-medium color-gray-80">
              {{ lblSuccessMessage }}
            </p>
          </bb-col>
          <bb-col col="24" class="m-t-30">
            <bb-button
              id="thankYouCloseButton"
              color="mint"
              inverted
              block
              display="block"
              @click="close"
            >
              {{ lblClose }}
            </bb-button>
          </bb-col>
        </bb-row>
        <bb-container v-if="loading">
          <bb-row justify="center">
            <bb-col col="3">
              <bb-spinner class="m-t-80 m-b-80" />
            </bb-col>
          </bb-row>
        </bb-container>

        <bb-form
          v-if="includeForm"
          id="save"
          :submit-text="lblSave"
          :error-text="lblErrorText"
          @submit="validate"
        >
          <slot></slot>
          <template v-if="customSubmit" slot="submit" slot-scope="{ disabled, progress, buttonText }">
            <bb-row class="m-t-30 m-x-0" justify="end">
              <bb-col :col="size === 'x2' ? '6' : '12'" align-self="end" class="p-r-0">
                <bb-button
                  id="customCloseButton"
                  class="p-x-20"
                  color="red"
                  inverted
                  display="block"
                  @click="close"
                >
                  {{ lblCancel }}
                </bb-button>
              </bb-col>
              <bb-col :col="size === 'x2' ? '6' : '12'" align-self="end" class="p-r-0">
                <bb-button
                  id="submitButton"
                  class="p-x-20"
                  :disabled="disabled"
                  :class="{ 'btn--disabled': progress < 1 }"
                  color="mint"
                  type="submit"
                  display="block"
                >
                  {{ buttonText }}
                </bb-button>
              </bb-col>
            </bb-row>
          </template>
        </bb-form>

        <slot v-else></slot>
      </bb-modal>
      <bb-confirmation-dialog
        no-inverted
        :visible="showConfirmationModal"
        :title="lblConfirmationHeading"
        :message="lblClosingModal"
        :lbl-no="lblClosingDecline"
        :lbl-yes="lblClosingAgree"
        @close="hideConfirmationModal(false)"
        @noClick="hideConfirmationModal(false)"
        @yesClick="hideConfirmationModal(true)"
      />
    </div>
  </bb-container>
</template>
<script>
  import '@bigbank/interface-components/dist/svg/ui/check-circle-outline';
  import BbConfirmationDialog from './ConfirmationDialog';
  import { BbContainer,
           BbRow,
           BbCol,
           BbButton,
           BbSpinner,
           BbForm,
           BbModal,
           BbIcon } from '@bigbank/interface-components';
  import i18n from '../../utils/common/i18n';
  import { RouteNames } from '@/types/common/enums';

  export default {
    name: 'modal-form-wrapper',
    components: {
      BbConfirmationDialog,
      BbContainer,
      BbRow,
      BbCol,
      BbButton,
      BbSpinner,
      BbForm,
      BbModal,
      BbIcon,
    },
    inject: [
      '$validator',
    ],
    props: {
      lblCancel: {
        type: String,
        default: i18n.tc('modalFormWrapper.cancel'),
      },
      lblSave: {
        type: String,
        default: i18n.tc('modalFormWrapper.save'),
      },
      lblClosingModal: {
        default: i18n.tc('modalFormWrapper.areYouSure'),
        type: String,
      },
      lblClosingAgree: {
        default: i18n.tc('common.yes'),
        type: String,
      },
      lblClosingDecline: {
        default: i18n.tc('common.no'),
        type: String,
      },
      lblSuccessMessage: {
        default: i18n.tc('modalFormWrapper.done'),
        type: String,
      },
      lblClose: {
        type: String,
        default: i18n.tc('modalFormWrapper.close'),
      },
      lblErrorText: {
        type: String,
        default: i18n.tc('modalFormWrapper.fixErrors'),
      },
      loading: {
        default: false,
        type: Boolean,
      },
      visible: {
        default: false,
        type: Boolean,
      },
      thankYouView: {
        default: false,
        type: Boolean,
      },
      heading: {
        default:  i18n.tc('modalFormWrapper.heading'),
        type: String,
      },
      lblConfirmationHeading: {
        type: String,
      },
      size: {
        default: 'x3',
        type: String,
      },
      showConfirmationModal: Boolean,
      to: Object,
      centerVertically: Boolean,
      customSubmit: Boolean,
      includeForm: Boolean,
      disableClose: Boolean,
    },
    data() {
      return {
        isFullyVisible: true,
      };
    },
    mounted() {
      this.isModalFullyVisible();
      this.$nextTick(() => {
        window.addEventListener('resize', this.isModalFullyVisible);
      });
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.isModalFullyVisible);
    },
    methods: {
      close () {
        this.$emit('close', false);
      },
      isModalFullyVisible() {
        this.isFullyVisible = this.$el.querySelector('.bb-modal').clientHeight < window.innerHeight;
      },
      validate () {
        this.$validator.validateAll().then(result => {
          result && this.submit();
        });
      },
      submit (request = true) {
        try {
          this.$emit('submit', request);
        } catch (err) {
          this.$notification.show(err).error();
          this.$router.push({
            name: RouteNames.ERROR,
          }).catch(() => {});
        }
      },
      hideConfirmationModal (type) {
        if (type) {
          this.submit(false);
          this.to && this.$router.push(this.to);
        } else {
          this.$emit('hideConfirmationModal');
        }
      },
    },
  };
</script>

<style>
  .modal-disabled {
    display: none;
  }
  .modal-form-wrapper .bb-modal {
    overflow: visible;
  }
  .no-gutters > [class*='col-'].p-l-15 {
    padding-left: 15px;
  }
</style>
