<template>
  <bb-modal
    :visible="visible"
    flavor="client"
    style="z-index:2000"
    @close="close"
  >
    <bb-icon
      size="fluid"
      fill="default"
      animated
      name="illustration-session-expired"
    />
    <div class="align-center">
      {{ $t('inactivityModal.logoutWarningStart') }} {{ logoutInTime }}
    </div>
    <div class="d-flex justify-content-around m-t-30">
      <bb-button
        id="logoutButton"
        color="red"
        display="ticket"
        class="m-r-5 p-x-5"
        @click="logout"
      >
        {{ $t('inactivityModal.logoutButton') }}
      </bb-button>
      <bb-button
        id="closeButton"
        color="mint"
        display="ticket"
        class="m-l-5 p-x-5"
        @click="close"
      >
        {{ $t('inactivityModal.continueButton') }}
      </bb-button>
    </div>
  </bb-modal>
</template>
<script>
  import '@bigbank/interface-components/dist/svg/illustration/session-expired';
  import heartbeat from '@/utils/common/heartbeat';
  import { BbButton,
           BbModal,
           BbIcon } from '@bigbank/interface-components';

  export default {
    name: 'inactivity-modal',
    components: {
      BbButton,
      BbModal,
      BbIcon,
    },
    props: {
      logoutTimeout: {
        type: Number,
        // 1 hour
        default: 60 * 60,
      },
      inactivityWarningTimeout: {
        type: Number,
        // 5 minutes
        default: 5 * 60,
      },
      logoutCountdownEventName: {
        type: String,
        default: 'logoutCountdown',
      },
    },
    data () {
      return {
        visible: false,
        timer: {},
        logoutInSeconds: this.logoutTimeout,
      };
    },
    computed: {
      logoutInTime () {
        let minutes = ~~(this.logoutInSeconds / 60);
        let seconds = this.logoutInSeconds - (minutes * 60);
        minutes = `${minutes}`.padStart(2, '0');
        seconds = `${seconds}`.padStart(2, '0');

        return `${minutes}:${seconds}`;
      },
    },
    watch: {
      '$route.name' () {
        this.initTimeout();
      },
    },
    mounted () {
      this.initTimeout();
    },
    methods: {
      initTimeout () {
        heartbeat.killEvent(this.logoutCountdownEventName);
        if (this.$auth.isLoggedIn()) {
          setTimeout(() => {
            this.logoutInSeconds = this.logoutTimeout;
            heartbeat.createEvent(1, {
              name: this.logoutCountdownEventName,
            }, () => {
              this.logoutInSeconds = Math.max(0, --this.logoutInSeconds);
              if (this.logoutInSeconds <= this.inactivityWarningTimeout) {
                this.visible = true;
              }
              if (this.logoutInSeconds < 1) {
                this.logout();
              }
            });
          }, 200);
        }
      },
      logout () {
        this.$emit('logout');
      },
      close () {
        this.visible = false;
        this.initTimeout();
      },
    },
  };
</script>
