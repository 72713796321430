import i18n from '@/utils/common/i18n';

const messages = {
  '1001': i18n.tc('pamErrors.userDoesNotExist'),
  '1002': i18n.tc('pamErrors.invalidPassword'),
  '1003': i18n.tc('pamErrors.invalidChannel'),
  '1004': i18n.tc('pamErrors.passwordUsedBefore'),
  '1005': i18n.tc('pamErrors.passwordSimilarToUsername'),
  '1006': i18n.tc('pamErrors.passwordNotMeetMinimumRequirements'),
  '1007': i18n.tc('pamErrors.userIsInactive'),
  '1008': i18n.tc('pamErrors.systemFailure'),
  '1009': i18n.tc('pamErrors.mobilePhoneAlreadyInUse'),
  '1010': i18n.tc('pamErrors.noNecessaryAuthorizations'),
  '1011': i18n.tc('pamErrors.noNecessaryAuthorizations'),
  '1012': i18n.tc('pamErrors.emailAlreadyInUse'),
  '1013': i18n.tc('pamErrors.insertEmailAddress'),
  '1014': i18n.tc('pamErrors.insertPhoneNumber'),
  '1015': i18n.tc('pamErrors.usernameAlreadyInUse'),
  '1016': i18n.tc('pamErrors.personalCodeAlreadyInUse'),
  '1017': i18n.tc('pamErrors.notCorrectOrganizationCode'),
  '1018': i18n.tc('pamErrors.accountDeactivated'),
  '1019': i18n.tc('pamErrors.invalidUsernameOrPassword'),
  '1020': i18n.tc('pamErrors.invalidUser'),

  '2001': i18n.tc('pamErrors.partnerDoesNotExist'),
  '2002': i18n.tc('pamErrors.partnerNotActive'),
  '2003': i18n.tc('pamErrors.partnerNameAlreadyRegistered'),
  '2004': i18n.tc('pamErrors.partnerWithRegCodeAlreadyRegistered'),
  '2005': i18n.tc('pamErrors.currentLoginIpNotWhitelisted'),
  '2006': i18n.tc('pamErrors.creditLimitDecisionExpired'),

  '3001': i18n.tc('pamErrors.channelDoesNotExist'),
  '3002': i18n.tc('pamErrors.channelNotActive'),
  '3003': i18n.tc('pamErrors.channelNameAlreadyRegistered'),
  '3004': i18n.tc('pamErrors.invalidChannel'),
  '3005': i18n.tc('pamErrors.channelEmailIsAbsent'),

  '4001': i18n.tc('pamErrors.corporationDoesNotExist'),
  '4002': i18n.tc('pamErrors.corporationNameHasBeenRegistered'),
  '4003': i18n.tc('pamErrors.corporationRegCodeRegistered'),

  '5001': i18n.tc('pamErrors.invitationHasExpired'),
  '5002': i18n.tc('pamErrors.invitationHasExpired'),
  '5003': i18n.tc('pamErrors.invitationOnlyByEmail'),
  '5004': i18n.tc('pamErrors.invitationEmailMissing'),
  '5005': i18n.tc('pamErrors.invitationOnlySms'),
  '5006': i18n.tc('pamErrors.invitationMobilePhoneMissing'),
  '5007': i18n.tc('pamErrors.systemFailure'),
  '5008': i18n.tc('pamErrors.invitationForMobilePhoneAlreadyExists'),
  '5009': i18n.tc('pamErrors.partnerCantCreateInvitationForAdminRole'),
  '5010': i18n.tc('pamErrors.invitationWithEmailAlreadyExists'),
  '5011': i18n.tc('pamErrors.createChannelFirst'),
  '5012': i18n.tc('pamErrors.invitationWithPersonalCodeAlreadyExists'),

  '6001': i18n.tc('pamErrors.otpDoesNotExist'),
  '6002': i18n.tc('pamErrors.otpHasExpired'),
  '6003': i18n.tc('pamErrors.cannotSendNewOtpAsOldNotExpired'),
  '6004': i18n.tc('pamErrors.incorrectOtp'),
  '6005': i18n.tc('pamErrors.passwordIncorrectType'),
  '6006': i18n.tc('pamErrors.oneTimePasswordLimitExceeded'),
  '6007': i18n.tc('pamErrors.codeHasExpired'),

  '7001': i18n.tc('pamErrors.documentDoesNotExist'),
  '7002': i18n.tc('pamErrors.invalidDocumentType'),

  '8001': i18n.tc('pamErrors.passwordResetTokenDoesNotExist'),
  '8002': i18n.tc('pamErrors.passwordResetRequestFailed'),
  '8003': i18n.tc('pamErrors.passwordResetRequestTimeoutPassed'),
  '8004': i18n.tc('pamErrors.passwordResetDuplicatePersonalCode'),

  '9001': i18n.tc('pamErrors.commissionDoesNotExist'),
  '9002': i18n.tc('pamErrors.productDoesNotBelongToPartner'),
  '9003': i18n.tc('pamErrors.commissionAlreadyExists'),

  '10001': i18n.tc('pamErrors.userBonusDoesNotExist'),
  '10002': i18n.tc('pamErrors.userBonusAlreadyExists'),

  'GW-1001': i18n.tc('pamErrors.systemFailure'),
  'GW-1002': i18n.tc('pamErrors.loginFailedToAuthService'),
  'GW-1003': i18n.tc('pamErrors.noRightsToAccessPartnerSystem'),
  'GW-2001': i18n.tc('pamErrors.systemFailure'),
  'GW-2002': i18n.tc('pamErrors.systemFailure'),
  'GW-2003': i18n.tc('pamErrors.systemFailure'),
  'GW-2004': i18n.tc('pamErrors.systemFailure'),
  'GW-2005': i18n.tc('pamErrors.systemFailure'),
  'GW-2006': i18n.tc('pamErrors.systemFailure'),
  'GW-3001': i18n.tc('pamErrors.systemFailure'),
  'GW-4001': i18n.tc('pamErrors.noRightsToAccessPayPost'),

  'PBM-2000': i18n.tc('pamErrors.usersAccruedBonusCanNotBeExceeded'),
  'PBM-4000': i18n.tc('pamErrors.bonusAlreadyApplied'),
};

const error = (code) => {
  return messages[code] || `${i18n.tc('pamErrors.unknownError')} ${code}`;
};

export default error;
