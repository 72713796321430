export enum VehicleType {
    COLLATERAL = 'COLLATERAL',
    TRADE_IN = 'TRADE_IN'
}

export enum FiTaskDefinitionKeys {
    CREDIT_APPROVED = 'creditApproved',
    CREDIT_DECLINED = 'creditDeclined',
    CREDIT_DECLINED_BRING_CO_BORROWER = 'creditDeclinedBringCoBorrower',
    WAITING_DECISION = 'waitingDecision',
    APPLICANT_SIGNING = 'applicantSigning',
    CO_APPLICANT_SIGNING = 'coApplicantSigning',
    PARTNER_SIGNING = 'partnerSigning',
    CONTRACT_IN_REVIEW = 'contractInReview',
    APPROVED_WITH_CONDITIONS = 'approvedWithConditions',
    WAIT_COLLATERAL_DATA_FIX = 'waitCollateralDataFix',
    WAIT_FOR_PROVENIR_DECISION = 'waitForProvenirDecision',
}

export enum CommissionTypeCode {
    PERCENTAGE = 'PERCENTAGE',
    FIXED_SUM = 'FIXED_SUM'
}

