import dayjs from 'dayjs';

function validatePersonalCodeLithuaniaEstonia (value) {
  if (!value || value.length !== 11) {
    return false;
  }
  const birthDate = dayjs(getBirthDate(value));
  if (!birthDate.isValid() || dayjs().isAfter(dayjs())) {
    return false;
  }

  const numbers = (value || '0').split('').map(Number);
  const lastDigit = numbers[10];
  const checksumNumbers = numbers.slice(0, 10);

  const controlNumber = [
    calculateControlNumber(checksumNumbers, [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      1,
    ]),
    calculateControlNumber(checksumNumbers, [
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      1,
      2,
      3,
    ]),
    0,
  ].find(controlNumber => controlNumber !== 10);

  return lastDigit === controlNumber;
}

function calculateControlNumber (numbers, weights) {
  return numbers.reduce((sum, num, i) => sum + num * weights[i], 0) % 11;
}

function getBirthDate (value) {
  if (value) {
    const genderDigit = parseInt(value.substring(0, 1));
    const isFemale = genderDigit % 2 === 0;
    const century = (isFemale ? genderDigit : genderDigit + 1) / 2 + 17;

    return century + value.substring(1, 7);
  }

  return '';
}

function getGender (value) {
  if (value) {

    return [
      '4',
      '6',
    ].includes(value.charAt(0))
      ? 'FEMALE'
      : 'MALE';
  }

  return '';
}

export {
  validatePersonalCodeLithuaniaEstonia,
  getBirthDate,
  getGender,
};
