<template>
  <bb-modal
    size="x3"
    scroll
    :hide-close="deadlinePassed"
    :visible="isVisible"
    @close="$emit('close')"
  >
    <template slot="heading">
      {{ $t('amlPdfModal.title') }}
    </template>
    <embed
      :src="source"
      width="100%"
      height="4700px"
      type="application/pdf"
    >
    <bb-separator class="m-b-30" />
    <div class="d-flex align-items-center m-b-30">
      <bb-checkbox
        id="readingConfirmation"
        v-model="readingConfirmation"
        v-validate="'required'"
        class="m-r-50"
        name="readingConfirmation"
      >
        {{ $t('amlPdfModal.checkbox') }}
      </bb-checkbox>
      <bb-button
        class="w-100"
        :disabled="!readingConfirmation"
        :label="$t('amlPdfModal.submit')"
        @click="$emit('amlTrainingConfirmed')"
      />
    </div>
  </bb-modal>
</template>
<script>
  import {
    BbModal,
    BbSeparator,
    BbButton,
    BbCheckbox,
  } from '@bigbank/interface-components';
  import dayjs from 'dayjs';
  import { debounce } from 'lodash-es';

  export default {
    name: 'aml-pdf-modal',
    components: {
      BbModal,
      BbSeparator,
      BbButton,
      BbCheckbox,
    },
    inject: [
      '$validator',
    ],
    data () {
      return {
        source: '/files/LT_AML.pdf#navpanes=0&toolbar=0',
        scrolledToBottom: false,
        isVisible: true,
        readingConfirmation: false,
      };
    },
    computed: {
      deadlinePassed() {
        return this.$userData().amlTrainingDate
          ? Math.abs(dayjs(this.$userData().amlTrainingDate).diff(new Date, 'day')) >= 365
          : true;
      },
    },
  };
</script>
