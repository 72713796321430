import heartbeats from 'heartbeats';

const heart = heartbeats.createHeart(1000, 'heart');

function createEvent (beatInterval, options, callback) {
  heart.createEvent(beatInterval, options, callback);
}

function killEvent (name) {
  heart.killEvent(name);
}

function getEvent (name) {
  return heart.event(name);
}

export default {
  createEvent,
  killEvent,
  getEvent,
};
