import { UserData } from '@/types/types';
import { Domain, RoleCode } from '@/types/common/enums';
import dayjs from 'dayjs';

export function getShouldShowAmlModal(isLoggedIn: boolean = false) {

  const domain = window.config.DOMAIN;
  const userData: UserData = localStorage.user ? JSON.parse(localStorage.user) : {};

  const isSuperAdmin = userData.roleCode === RoleCode.BIGBANK_SUPER_ADMIN;
  const isAuditOrAccountant = [
    RoleCode.PARTNER_ACCOUNTANT,
    RoleCode.PARTNER_AUDIT,
  ].includes(userData.roleCode);

  const isQualifiedForAmlVideo = !isSuperAdmin && !isAuditOrAccountant;

  const deadlinePassed = userData.amlTrainingDate
    ? Math.abs(dayjs(userData.amlTrainingDate).diff(new Date, 'day')) >= 365
    : true;

  if (domain !== Domain.LV) {
    if (!deadlinePassed && isLoggedIn) {
      return false;
    }

    return isQualifiedForAmlVideo &&
        (!userData.amlTrainingDate || Math.abs(dayjs(userData.amlTrainingDate).diff(new Date, 'day')) >= 335);
  }

  return false;

}
