<template>
  <div>
    <bb-modal
      :visible="$notification.isActive()"
      flavor="card"
      size="x1"
      @close="close"
    >
      <template slot="heading">
        {{ $t(getErrorLabel) }}
      </template>
      <p>{{ $t($notification.getMessage()) }}</p>
      <bb-button
        id="closeNotificationModalButton"
        slot="footer"
        display="block"
        color="mint"
        class="p-x-a"
        @click="close"
      >
        {{ $t('notificationModal.confirm') }}
      </bb-button>
    </bb-modal>
  </div>
</template>
<script>
  import { BbButton,
           BbModal } from '@bigbank/interface-components';

  export default {
    name: 'notification-modal',
    components: {
      BbButton,
      BbModal,
    },
    computed: {
      getErrorLabel () {
        const types = {
          alert: this.$t('notificationModal.alert'),
          error: this.$t('notificationModal.error'),
        };

        return types[this.$notification.getType()];
      },
    },
    methods: {
      close () {
        this.$emit('close');
        this.$notification.hide();
      },
    },
  };
</script>
