import { pmsRequest } from '../utils/common/request';
import queryString from 'query-string';

export default {

  findUsers (parameters) {
    const uri = parameters
      ? `?${ queryString.stringify(parameters)}`
      : '';

    return pmsRequest({
      url: `/user${uri}`,
    });
  },

  createUser (user) {
    return pmsRequest({
      url: '/user',
      data: user,
      method: 'post',
    });
  },

  createUserFromInvitation (data, otpId, otpCode) {
    let headers;
    if (otpId && otpCode) {
      headers = {
        'X-BB-OTP': `id=${otpId};code=${otpCode}`,
      };
    }

    return pmsRequest({
      url: '/system-user/user-from-invitation',
      data,
      method: 'post',
      headers,
    });
  },

  getIsUsernameAvailable (name) {
    return pmsRequest({
      url: `/system-user/user/is-username-available?username=${name}`,
    });
  },

  getUserByName (name) {
    return pmsRequest({
      url: `/user/search?username=${name}`,
    });
  },

  getUser (id) {
    return pmsRequest({
      url: `/user/${id}`,
    });
  },

  updateUser (id, user) {
    return pmsRequest({
      url: `/user/${id}`,
      data: user,
      method: 'put',
    });
  },

  activateUser (id) {
    return pmsRequest({
      url: `/user/${id}/activation`,
      method: 'put',
    });
  },

  deactivateUser (id, status) {
    return pmsRequest({
      url: `/user/${id}/deactivation`,
      method: 'put',
      data: {
        status,
      },
    });
  },

  getPartnerUsers (partnerId) {
    return pmsRequest({
      url: `/partner/${partnerId}/user`,
    });
  },

  getChannelUsers (channelId) {
    return pmsRequest({
      url: `/user?channelId=${channelId}`,
    });
  },

  updatePassword (id, passwordRequest) {
    return pmsRequest({
      url: `/user/${id}/password`,
      data: passwordRequest,
      method: 'put',
    });
  },

  resetPassword (passwordRequest, otpId, otpCode) {
    let headers;
    if (otpId && otpCode) {
      headers = {
        'X-BB-OTP': `id=${otpId};code=${otpCode}`,
      };
    }

    return pmsRequest({
      url: '/system-user/password/reset',
      data: passwordRequest,
      method: 'put',
      headers,
    });
  },

  updateUserProfile (profile) {
    return pmsRequest({
      url: '/my/profile',
      data: profile,
      method: 'put',
    });
  },

  getCurrentUser () {
    return pmsRequest({
      url: '/my',
    });
  },

  requestPasswordResetLink (usernameOrPersonalCode) {
    return pmsRequest({
      url: '/system-user/password/request-reset-link',
      data: usernameOrPersonalCode,
      method: 'POST',
    });
  },

  getName (ids) {
    const uri = ids
      ? `?${ queryString.stringify({
        ids,
      })}`
      : '';

    return pmsRequest({
      url: `/user/name/${uri}`,
    });
  },
};
