<template>
  <modal-form-wrapper
    :thank-you-view="thankYouView"
    :lbl-success-message="$t('questionnaireModal.thankYouMessage')"
    :show-confirmation-modal="false"
    class="questionnaire-modal"
    :visible="true"
    size="x3"
    full-mobile
    center-vertically
    :heading="$t('questionnaireModal.title')"
    :to="{ name: 'Applications' }"
    @close="close"
  >
    <bb-box v-if="!thankYouView">
      <p class="text-center">
        {{ $t('questionnaireModal.subtitle') }}
      </p>
    </bb-box>
    <bb-form
      v-if="!thankYouView"
      :submit-text="$t('questionnaireModal.submit')"
      :error-text="$t('questionnaireModal.errorText')"
      :loading="loading"
      :disabled="loading || !hasContent"
      static-submit
      class="m-t-30"
      @submit="sendQuestionnaire"
    >
      <p v-if="!$isFi()">
        {{ $t('questionnaireModal.functionalitiesLabel') }}
      </p>
      <bb-row v-if="!$isFi()">
        <bb-col
          v-for="(functionality, index) in mostUsedFunctionalities"
          :key="index"
          :col="8"
        >
          <bb-checkbox
            id="functionalityCheckbox"
            v-model="mostUsedFunctionalities[index].checked"
            name="mostUsedFunctionality"
          >
            {{ functionality.label }}
          </bb-checkbox>
        </bb-col>
      </bb-row>
      <bb-row>
        <bb-col :col="24">
          <bb-textarea
            id="shouldBeImprovedTextarea"
            v-model="shouldBeImproved"
            v-validate="'max:500'"
            rows="3"
            :label="$t('questionnaireModal.shouldBeImprovedLabel')"
            :data-vv-as="$t('questionnaireModal.shouldBeImprovedLabel')"
            name="shouldBeImproved"
          />
        </bb-col>
      </bb-row>
      <bb-row>
        <bb-col :col="24">
          <bb-textarea
            id="missingFunctionalitiesTextarea"
            v-model="missingFunctionalities"
            v-validate="'max:500'"
            rows="3"
            :label="$t('questionnaireModal.missingFunctionalitiesLabel')"
            :data-vv-as="$t('questionnaireModal.missingFunctionalitiesLabel')"
            name="missingFunctionalities"
          />
        </bb-col>
      </bb-row>
    </bb-form>
  </modal-form-wrapper>
</template>
<script>
  import { BbForm,
           BbTextarea,
           BbRow,
           BbCol,
           BbCheckbox,
           BbBox } from '@bigbank/interface-components';
  import { QuestionnaireType, RouteNames } from '@/types/common/enums';
  import ModalFormWrapper from '../../components/common/ModalFormWrapper';
  import QuestionnaireController from '@/controllers/QuestionnaireController';
  import UserController from '@/controllers/UserController';

  export default {
    name: 'questionnaire-modal',
    components: {
      BbForm,
      BbTextarea,
      ModalFormWrapper,
      BbRow,
      BbCol,
      BbCheckbox,
      BbBox,
    },
    $_veeValidate: {
      validator: 'new',
    },
    data() {
      return {
        thankYouView: false,
        loading: false,
        shouldBeImproved: null,
        missingFunctionalities: null,
        mostUsedFunctionalities: [
          {
            label: this.$t('questionnaireModal.functionalities.searchByAppNr'),
            checked: false,
          },
          {
            label: this.$t('questionnaireModal.functionalities.documentsUpload'),
            checked: false,
          },
          {
            label: this.$t('questionnaireModal.functionalities.applicationsAndFiltering'),
            checked: false,
          },
          {
            label: this.$t('questionnaireModal.functionalities.creatingApplication'),
            checked: false,
          },
          {
            label: this.$t('questionnaireModal.functionalities.continuingApplication'),
            checked: false,
          },
          {
            label: this.$t('questionnaireModal.functionalities.prefillingApplication'),
            checked: false,
          },
          {
            label: this.$t('questionnaireModal.functionalities.calculator'),
            checked: false,
          },
          {
            label: this.$t('questionnaireModal.functionalities.csvExport'),
            checked: false,
          },
        ],
      };
    },
    computed: {
      hasContent () {
        return this.mostUsedFunctionalities.some(item => item.checked) || this.missingFunctionalities || this.shouldBeImproved;
      },
    },
    methods: {
      async sendQuestionnaire () {
        this.loading = true;

        const questionsAndAnswersModel = [];

        if (!this.$isFi() && this.mostUsedFunctionalities.filter(answer => answer.checked === true).length !== 0) {
          questionsAndAnswersModel.push({
            question: this.$t('questionnaireModal.functionalitiesLabel'),
            answer: this.mostUsedFunctionalities.filter(answer => answer.checked === true).map(a => a.label).join(', '),
          });
        }

        if (this.shouldBeImproved) {
          questionsAndAnswersModel.push({
            question: this.$t('questionnaireModal.shouldBeImprovedLabel'),
            answer: this.shouldBeImproved,
          });
        }

        if (this.missingFunctionalities) {
          questionsAndAnswersModel.push({
            question: this.$t('questionnaireModal.missingFunctionalitiesLabel'),
            answer: this.missingFunctionalities,
          });
        }

        const payload = {
          partnerId: this.$userData().partnerId,
          partnerUserId: this.$userData().id,
          questionnaireType: QuestionnaireType.FEEDBACK_FORM,
          questionsAndAnswersModel,
        };

        await QuestionnaireController.answerFeedbackQuestionnaire(payload);
        const { data } = await UserController.getCurrentUser();
        localStorage.user = JSON.stringify(data);

        this.thankYouView = true;
        this.loading = false;
        try {
          this.$emit('submit');
        } catch (err) {
          this.$notification.show(err).error();
          this.$router.push({
            name: RouteNames.ERROR,
          }).catch(() => {});
        }
      },
      close () {
        this.$router.push({
          name: RouteNames.APPLICATIONS,
        }).catch(() => {});
        this.$emit('close');
      },
    },
  };
</script>
<style lang="scss">
.questionnaire-modal {
  .bb-modal {
    &__title {
      text-align: center;
    }
  }
}

</style>
