<template>
  <bb-modal
    public-size="m"
    class="align-center"
    visible
    flavor="user"
    @close="hideModal"
  >
    <div v-if="!resetLinkSent">
      <h4 class="color-navy modal__heading">
        {{ $t('forgotPassword.title') }}
      </h4>

      <p class="f-small color-gray-80 m-t-20">
        {{ $t('forgotPassword.insert') }}
      </p>

      <div class="align-center m-t-30 p-b-10">
        <bb-form
          :loading="loading"
          uppercase-buttons
        >
          <bb-input
            id="usernameOrPersonalCodeInput"
            v-model="usernameOrPersonalCode"
            v-validate="'required'"
            class="align-left m-b-30"
            type="text"
            name="usernameOrPersonalCode"
            :label="$t('forgotPassword.usernameOrPersonalCode')"
          />
          <p
            v-if="lblError"
            class="color-red align-center f-smallest m-t-30 m-b-30"
          >
            {{ lblError }}
          </p>
          <template slot="submit">
            <bb-button
              id="submitButton"
              :disabled="disabled"
              @click="submit"
            >
              {{ $t('forgotPassword.requestResetLink') }}
            </bb-button>
          </template>
        </bb-form>
      </div>

      <div class="align-center">
        <bb-button
          id="hideButton"
          color="gray"
          display="link"
          @click="hideModal"
        >
          {{ $t('forgotPassword.back') }}
        </bb-button>
      </div>
    </div>

    <div v-if="resetLinkSent">
      <div class="align-center m-t-30 p-b-30">
        <bb-icon name="ui-check-circle-outline" size="128" fill="mint" />
      </div>
      <div class="align-center m-t-30">
        <p class="f-medium color-gray-80">
          {{ $t('forgotPassword.sent') }}
        </p>
      </div>
      <div class="align-center m-t-30">
        <bb-button
          id="hideResetModalButton"
          color="mint"
          inverted
          display="block"
          @click="hideModal"
        >
          {{ $t('forgotPassword.back') }}
        </bb-button>
      </div>
    </div>
  </bb-modal>
</template>

<script>
  import '@bigbank/interface-components/dist/svg/ui/check-circle-outline';
  import { RouteNames } from '@/types/common/enums';
  import { BbButton,
           BbForm,
           BbInput,
           BbModal,
           BbIcon } from '@bigbank/interface-components';

  export default {
    name: 'forgot-password',
    components: {
      BbButton,
      BbForm,
      BbInput,
      BbModal,
      BbIcon,
    },
    inject: [
      '$validator',
    ],
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      resetLinkSent: {
        type: Boolean,
        default: false,
      },
      lblError: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        usernameOrPersonalCode: null,
        disabled: true,
      };
    },
    watch: {
      usernameOrPersonalCode: {
        handler (val) {
          this.disabled = val.length < 2;
        },
      },
    },
    methods: {
      hideModal () {
        this.$emit('close');
      },
      submit () {
        try {
          this.$emit('submit', this.usernameOrPersonalCode);
        } catch (err) {
          this.$notification.show(err).error();
          this.$router.push({
            name: RouteNames.ERROR,
          }).catch(() => {});
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@bigbank/interface-components/dist/src/scss/variables';

  .modal__heading {
      font-family: $max-pro-demi-bold;
  }
</style>
