<template>
  <bb-container>
    <bb-row class="m-t-10 m-b-10">
      <bb-col v-if="!$isFi()">
        <bb-button
          id="startMobileIdVerificationButton"
          class="w-100"
          display="image"
          @click="startVerification(verificationMethods.MOBILE_ID)"
        >
          <bb-icon name="client-mobile-id" size="24" fill="navy-90" />
          <p class="f-color-gray-70 m-l-10">
            {{ $t('digitalAuthenticationMethods.mobileId') }}
          </p>
        </bb-button>
      </bb-col>
      <bb-col v-if="!$isFi()">
        <bb-button
          id="startSmartIdVerificationButton"
          class="w-100"
          display="image"
          @click="startVerification(verificationMethods.SMART_ID)"
        >
          <bb-icon name="client-smart-id" size="24" fill="navy-90" />
          <p class="f-color-gray-70 m-l-10">
            {{ $t('digitalAuthenticationMethods.smartId') }}
          </p>
        </bb-button>
      </bb-col>
      <bb-col v-if="$isEe()">
        <bb-button
          id="startIdCardVerificationButton"
          class="w-100 m-t-20"
          display="image"
          @click="startVerification(verificationMethods.ID_CARD)"
        >
          <p class="f-color-gray-70 m-l-10">
            {{ $t('digitalAuthenticationMethods.idCard') }}
          </p>
        </bb-button>
      </bb-col>
    </bb-row>
    <bb-modal
      :visible="showModal && !challengeId"
      flavor="card"
      public-size="m"
      class="align-center signing-modal"
      full-mobile
      static-overlay
      @close="closeModal"
    >
      <bb-icon
        name="illustration-no-account"
        fill="original"
        size="fluid"
        animated
      />
      <p class="color-gray-90 p-b-20 p-t-10">
        {{ verificationHeader }}
      </p>
      <bb-form
        :submit-text="$t('digitalAuthenticationMethods.submit')"
        :error-text="$t('digitalAuthenticationMethods.errorText')"
        :loading="loading"
        :enabled="loading"
        static-submit
        @submit="initiateAuthentication"
      >
        <bb-masked-input
          v-if="activeVerificationMethod === verificationMethods.MOBILE_ID"
          id="mobilePhoneInput"
          v-model="mobileNumber"
          v-validate="'required|mobilePhone' + $selectedDomain()"
          framed
          type="tel"
          :mask="$maskedMobilePhone()"
          name="mobilePhone"
          :label="$t('digitalAuthenticationMethods.mobileNumber')"
          :data-vv-as="$t('digitalAuthenticationMethods.mobileNumber')"
        />
        <bb-input
          id="personalCodeInput"
          v-model="personalIdentityCode"
          v-validate="'required|personalCode' + $selectedDomain()"
          :label="$t('digitalAuthenticationMethods.personalIdentityCode')"
          :data-vv-as="$t('digitalAuthenticationMethods.personalIdentityCode')"
          name="personalIdentityCode"
        />
      </bb-form>
      <div v-if="$isEe() || $isLt()" class="color-blue p-t-20 f-smallest">
        <bb-icon
          name="ui-alert-circle-outline"
          size="18"
          fill="yellow"
        />
        {{ $t('digitalAuthenticationMethods.multipleAccountWarning') }}
      </div>
    </bb-modal>
    <bb-modal
      :visible="!!challengeId"
      flavor="card"
      public-size="m"
      class="align-center"
      full-mobile
      static-overlay
      @close="closeModal"
    >
      <bb-icon :name="iconName" size="64" fill="original" />
      <p class="m-t-20">
        {{ challengeId }}
      </p>
      <p class="f-color-gray-70 m-t-20 m-b-20">
        {{ $t('digitalAuthenticationMethods.pinText') }}
      </p>
      <bb-link
        id="closeModalLink"
        @click="closeModal"
      >
        {{ $t('digitalAuthenticationMethods.cancel') }}
      </bb-link>
    </bb-modal>
  </bb-container>
</template>
<script>
  import { BbRow,
           BbCol,
           BbIcon,
           BbButton,
           BbContainer,
           BbModal,
           BbForm,
           BbMaskedInput,
           BbInput,
           BbLink } from '@bigbank/interface-components';
  import '@bigbank/interface-components/dist/svg/client/smart-id';
  import '@bigbank/interface-components/dist/svg/illustration/no-account';
  import '@bigbank/interface-components/dist/svg/client/mobile-id';
  import '@bigbank/interface-components/dist/svg/ui/alert-circle-outline';
  import { VerificationMethods } from '@/types/common/enums';

  export default {
    name: 'digital-authentication-methods',
    components: {
      BbRow,
      BbCol,
      BbIcon,
      BbButton,
      BbContainer,
      BbModal,
      BbForm,
      BbMaskedInput,
      BbInput,
      BbLink,
    },
    $_veeValidate: {
      validator: 'new',
    },
    props: {
      verificationCode: {
        type: String,
      },
      loading: {
        'type': Boolean,
        'default': false,
      },
      closeAuthenticationModal: {
        'type': Boolean,
        'default': false,
      },
    },
    data () {
      return {
        verificationMethods: VerificationMethods,
        activeVerificationMethod: null,
        showModal: false,
        mobileNumber: '',
        personalIdentityCode: '',
        challengeId: null,
      };
    },
    computed: {
      verificationHeader () {
        return this.activeVerificationMethod === VerificationMethods.MOBILE_ID
          ? this.$t('digitalAuthenticationMethods.mobileIdHeader')
          : this.$t('digitalAuthenticationMethods.smartIdHeader');
      },
      iconName () {
        return this.activeVerificationMethod === VerificationMethods.MOBILE_ID
          ? 'client-mobile-id'
          : 'client-smart-id';
      },
    },
    watch: {
      verificationCode (val) {
        this.challengeId = val;
      },
      closeAuthenticationModal (val) {
        if (val) {
          this.closeModal();
        }
      },
    },
    methods: {
      startVerification (method) {
        if (method === VerificationMethods.ID_CARD) {
          this.activeVerificationMethod = method;

          return this.$emit('initiateIdCardAuthentication');
        }

        this.activeVerificationMethod = method;
        this.showModal = true;
      },
      initiateAuthentication () {
        this.$emit('initiateDigitalAuthentication', {
          method: this.activeVerificationMethod,
          personalIdentityCode: this.personalIdentityCode,
          phoneNumber: this.mobileNumber,
        });
      },
      closeModal () {
        this.showModal = false;
        this.$nextTick(() => {
          this.mobileNumber = '';
          this.personalIdentityCode = '';
          this.challengeId = null;
          this.$emit('stopDigitalAuthentication');
        });
      },
    },
  };
</script>

<style>
.signing-modal .bb-modal__body {
  max-height: 65vh !important;
}
</style>
