import { Domain } from '@/types/common/enums';

export const inputMasks = [
  {
    value: 'PHONE',
    country: Domain.LT,
    mask: '+370 11111111',
  },
  {
    value: 'PHONE',
    country: Domain.LV,
    mask: '+37\\1 11111111',
  },
  {
    value: 'PHONE',
    country: Domain.EE,
    mask: '+372 11111111',
  },
  {
    value: 'PHONE',
    country: Domain.FI,
    mask: '+358 1111111111',
  },
  {
    value: 'MOBILEPHONE',
    country: Domain.LT,
    mask: '+370 61111111',
  },
  {
    value: 'MOBILEPHONE',
    country: Domain.LV,
    mask: '+37\\1 11111111',
  },
  {
    value: 'MOBILEPHONE',
    country: Domain.EE,
    mask: '+372 11111111',
  },
  {
    value: 'MOBILEPHONE',
    country: Domain.FI,
    mask: '+358 1111111111',
  },
  {
    value: 'POSTALCODE',
    country: Domain.LT,
    mask: 'LT-11111',
  },
  {
    value: 'POSTALCODE',
    country: Domain.LV,
    mask: 'L\\V-1111',
  },
  {
    value: 'POSTALCODE',
    country: Domain.EE,
    mask: '11111',
  },
  {
    value: 'POSTALCODE',
    country: Domain.FI,
    mask: '11111',
  },
];

export function findInputMask (type, country) {
  if (country === '') {
    return '';
  }

  const countryMask = inputMasks.find(mask => mask.value === type && mask.country === country);

  return countryMask ? countryMask.mask : null;
}
