import { Domain, RoleCode, RouteNames } from '@/types/common/enums';

export const getPrivileges = () => {
  return {
    [Domain.EE]: {
      [RoleCode.PARTNER_ADMIN]: {
        'edit': [
          'Channel',
          'User',
          'Profile',
        ],
        'add': [
          'Channel',
          'New',
        ],
        'delete': [
          'user',
          'channel',
        ],
        'deactivate': [
          'User',
        ],
        'start': [
          'Application',
        ],
        'see': [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.INVITATIONS,
          RouteNames.COMMISSIONS,
          RouteNames.GIFT_CARDS,
          RouteNames.DOCUMENTS,
          RouteNames.INDICATIVE_CALCULATOR,
          'ExportCsv',
        ],
        'filter': [
          'Channel',
          'Channel Type',
        ],
        'access': [
          RouteNames.ENTITIES,
          'Create Channel',
          'Create User Invitation',
        ],
        'exportToCSV': [
          'Additional Columns',
        ],
      },
      [RoleCode.PARTNER_MANAGER]: {
        'edit': [
          'User',
          'Profile',
        ],
        'delete': [
          'user',
        ],
        'deactivate': [
          'User',
        ],
        'start': [
          'Application',
        ],
        'see': [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.GIFT_CARDS,
          RouteNames.DOCUMENTS,
          RouteNames.INDICATIVE_CALCULATOR,
          'ExportCsv',
        ],
        'access': [
          RouteNames.ENTITIES,
          'Create User Invitation',
        ],
      },
      [RoleCode.PARTNER_EMPLOYEE]: {
        edit: [
          'Profile',
        ],
        start: [
          'Application',
        ],
        see: [
          RouteNames.APPLICATIONS,
          'applicationsFilters',
          RouteNames.GIFT_CARDS,
          RouteNames.DOCUMENTS,
          RouteNames.INDICATIVE_CALCULATOR,
          'ExportCsv',
        ],
      },
      [RoleCode.PARTNER_AUDIT]: {
        edit: [
          'Profile',
        ],
        see: [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.INVITATIONS,
          RouteNames.COMMISSIONS,
          RouteNames.GIFT_CARDS,
          RouteNames.DOCUMENTS,
          RouteNames.INDICATIVE_CALCULATOR,
          'ExportCsv',
        ],
        filter: [
          'Channel',
          'Channel Type',
        ],
        access: [
          RouteNames.ENTITIES,
        ],
        exportToCSV: [
          'Additional Columns',
        ],
      },
      [RoleCode.BIGBANK_SUPER_ADMIN]: {
        see: [
          RouteNames.APPLICATIONS,
          'applicationsFilters',
        ],
        filter: [
          'Channel',
          'Channel Type',
        ],
      },
    },
    [Domain.LT]: {
      [RoleCode.PARTNER_ADMIN]: {
        'edit': [
          'Channel',
          'User',
          'Profile',
        ],
        'add': [
          'Channel',
          'User',
          'New',
        ],
        'delete': [
          'user',
        ],
        'deactivate': [
          'User',
        ],
        'start': [
          'Application',
        ],
        'see': [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.INVITATIONS,
          RouteNames.COMMISSIONS,
          RouteNames.DOCUMENT_ARCHIVING,
          RouteNames.INDICATIVE_CALCULATOR,
          RouteNames.DOCUMENTS,
        ],
        'filter': [
          'Channel',
          'Channel type',
        ],
        'access': [
          RouteNames.ENTITIES,
          'Create Channel',
          'Create User Invitation',
        ],
      },
      [RoleCode.PARTNER_MANAGER]: {
        'edit': [
          'User',
          'Profile',
        ],
        'add': [
          'User',
          'New',
        ],
        'delete': [
          'User',
        ],
        'deactivate': [
          'User',
        ],
        'start': [
          'Application',
        ],
        'see': [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.INDICATIVE_CALCULATOR,
          RouteNames.DOCUMENT_ARCHIVING,
          RouteNames.DOCUMENTS,
        ],
        'access': [
          RouteNames.ENTITIES,
          'Create User Invitation',
          RouteNames.DOCUMENT_ARCHIVING,
          RouteNames.DOCUMENTS,
        ],
      },
      [RoleCode.PARTNER_EMPLOYEE]: {
        start: [
          'Application',
          'Profile',
        ],
        see: [
          RouteNames.APPLICATIONS,
          'applicationsFilters',
          RouteNames.INDICATIVE_CALCULATOR,
          RouteNames.DOCUMENT_ARCHIVING,
          RouteNames.DOCUMENTS,
        ],
        access: [
          RouteNames.DOCUMENT_ARCHIVING,
          RouteNames.DOCUMENTS,
        ],
      },
      [RoleCode.BIGBANK_SUPER_ADMIN]: {
        see: [
          RouteNames.APPLICATIONS,
          RouteNames.INDICATIVE_CALCULATOR,
          'applicationsFilters',
        ],
      },
      [RoleCode.PARTNER_AUDIT]: {
        edit: [
          'Profile',
        ],
        see: [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.INVITATIONS,
          RouteNames.COMMISSIONS,
          RouteNames.DOCUMENT_ARCHIVING,
          RouteNames.INDICATIVE_CALCULATOR,
          RouteNames.DOCUMENTS,
        ],
        access: [
          RouteNames.ENTITIES,
          RouteNames.DOCUMENTS,
        ],
        filter: [
          'Channel',
          'Channel type',
        ],
      },
    },
    [Domain.FI]: {
      [RoleCode.PARTNER_ADMIN]: {
        'edit': [
          'Channel',
          'User',
          'Profile',
        ],
        'add': [
          'Channel',
          'User',
          'New',
        ],
        'delete': [
          'user',
          'channel',
        ],
        'deactivate': [
          'User',
        ],
        'start': [
          'Application',
        ],
        'see': [
          RouteNames.RESIDUALS,
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          RouteNames.PAID_COMMISSIONS_REPORT,
          'filters',
          'applicationsFilters',
          RouteNames.INVITATIONS,
          RouteNames.COMMISSIONS,
          RouteNames.DOCUMENTS,
        ],
        'filter': [
          'Channel',
          'Channel Type',
        ],
        'access': [
          RouteNames.ENTITIES,
          'Create Channel',
          'Create User Invitation',
        ],
      },
      [RoleCode.PARTNER_MANAGER]: {
        'edit': [
          'User',
          'Profile',
        ],
        'add': [
          'User',
          'New',
        ],
        'delete': [
          'user',
        ],
        'deactivate': [
          'User',
        ],
        'start': [
          'Application',
        ],
        'see': [
          RouteNames.RESIDUALS,
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.DOCUMENTS,
        ],
        'access': [
          RouteNames.ENTITIES,
          'Create User Invitation',
        ],
      },
      [RoleCode.PARTNER_EMPLOYEE]: {
        start: [
          'Application',
          'Profile',
        ],
        see: [
          RouteNames.APPLICATIONS,
          'applicationsFilters',
          RouteNames.DOCUMENTS,
          RouteNames.RESIDUALS,
        ],
      },
      [RoleCode.PARTNER_ACCOUNTANT]: {
        edit: [
          'Profile',
        ],
        start: [
          'Profile',
        ],
        see: [
          RouteNames.PAID_COMMISSIONS_REPORT,
        ],
      },
      [RoleCode.PARTNER_AUDIT]: {
        edit: [
          'Profile',
        ],
        see: [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          RouteNames.RESIDUALS,
          RouteNames.PAID_COMMISSIONS_REPORT,
          'filters',
          'applicationsFilters',
          RouteNames.INVITATIONS,
          RouteNames.COMMISSIONS,
          RouteNames.DOCUMENTS,
        ],
        filter: [
          'Channel',
          'Channel Type',
        ],
        access: [
          RouteNames.ENTITIES,
        ],
      },
      [RoleCode.BIGBANK_SUPER_ADMIN]: {
        see: [
          RouteNames.APPLICATIONS,
          RouteNames.PAID_COMMISSIONS_REPORT,
          RouteNames.RESIDUALS,
        ],
        filter: [
          'Channel',
          'Channel Type',
        ],
      },
    },
    [Domain.LV]: {
      [RoleCode.PARTNER_ADMIN]: {
        'edit': [
          'Channel',
          'User',
          'Profile',
        ],
        'add': [
          'Channel',
          'User',
          'New',
        ],
        'delete': [
          'user',
          'channel',
        ],
        'deactivate': [
          'User',
        ],
        'start': [
          'Application',
        ],
        'see': [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.INVITATIONS,
          RouteNames.DOCUMENTS,
        ],
        'filter': [
          'Channel',
          'Channel Type',
        ],
        'access': [
          RouteNames.ENTITIES,
          'Create Channel',
          'Create User Invitation',
        ],
      },
      [RoleCode.PARTNER_MANAGER]: {
        'edit': [
          'User',
          'Profile',
        ],
        'add': [
          'User',
          'New',
        ],
        'delete': [
          'user',
        ],
        'deactivate': [
          'User',
        ],
        'start': [
          'Application',
        ],
        'see': [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.DOCUMENTS,
        ],
        'access': [
          RouteNames.ENTITIES,
          'Create User Invitation',
        ],
      },
      [RoleCode.PARTNER_EMPLOYEE]: {
        edit: [
          'Profile',
        ],
        start: [
          'Application',
        ],
        see: [
          RouteNames.APPLICATIONS,
          'applicationsFilters',
          RouteNames.DOCUMENTS,
        ],
      },
      [RoleCode.PARTNER_AUDIT]: {
        edit: [
          'Profile',
        ],
        see: [
          RouteNames.ENTITIES,
          RouteNames.APPLICATIONS,
          'filters',
          'applicationsFilters',
          RouteNames.INVITATIONS,
          RouteNames.DOCUMENTS,
        ],
        filter: [
          'Channel',
          'Channel Type',
        ],
        access: [
          RouteNames.ENTITIES,
        ],
      },
      [RoleCode.BIGBANK_SUPER_ADMIN]: {
        see: [
          RouteNames.APPLICATIONS,
        ],
        filter: [
          'Channel',
          'Channel Type',
        ],
      },
    },
    [Domain.INTERNAL]: {
      [RoleCode.BIGBANK_ADMIN]: {
        'add': [
          RouteNames.CHANNEL,
          RouteNames.USER,
          'Partner',
          'New',
          'Commission',
          'User Bonus',
          'PartnerUserApplicationBonus',
          RouteNames.DOCUMENTS,
          RouteNames.USERS_REPORT,
          RouteNames.PARTNERS_REPORT,
          RouteNames.MANAGE_QUESTIONNAIRE,
        ],
        'delete': [
          'channel',
          'user',
          'partner',
        ],
        'see': [
          RouteNames.ENTITIES,
          RouteNames.PARTNER_PRODUCTS,
          'applicationsFilters',
          'filters',
          RouteNames.INVITATIONS,
          RouteNames.COMMISSIONS,
          RouteNames.USER_BONUSES,
          RouteNames.GIFT_CARD_MANAGEMENT,
          RouteNames.BONUS_APPLICATIONS,
          RouteNames.DOCUMENTS,
          RouteNames.USERS_REPORT,
          RouteNames.PARTNERS_REPORT,
          RouteNames.USER_BONUS_REPORT,
          RouteNames.QUESTIONNAIRE_REPORT,
          RouteNames.PARTNER_PRODUCTS,
          RouteNames.APPLICATIONS,
          RouteNames.RESIDUALS,
          RouteNames.PAID_COMMISSIONS_REPORT,
          RouteNames.MANAGE_QUESTIONNAIRE,
          RouteNames.INDICATIVE_CALCULATOR,
          RouteNames.BANNERS,
        ],
        'access': [
          RouteNames.APPLICATIONS,
          RouteNames.ENTITIES,
          RouteNames.RESIDUALS,
          'Create Channel',
          'Create User Invitation',
          'Create Corporation',
          'Create Partner',
          RouteNames.MANAGE_QUESTIONNAIRE,
        ],
        'field': [
          'amlTrainingDate',
        ],
      },
      [RoleCode.BIGBANK_MANAGER]: {
        add: [
          RouteNames.DOCUMENTS,
        ],
        see: [
          RouteNames.ENTITIES,
          'filters',
          'applicationsFilters',
          RouteNames.INVITATIONS,
          'Gift Card Management',
          'Bonus Applications',
          RouteNames.DOCUMENTS,
          RouteNames.USERS_REPORT,
          RouteNames.PARTNERS_REPORT,
          RouteNames.USER_BONUS_REPORT,
          RouteNames.PARTNER_PRODUCTS,
          RouteNames.QUESTIONNAIRE_REPORT,
          RouteNames.PAID_COMMISSIONS_REPORT,
          RouteNames.INDICATIVE_CALCULATOR,
          RouteNames.BANNERS,
        ],
        access: [
          RouteNames.ENTITIES,
        ],
        field: [
          'amlTrainingDate',
        ],
      },
      [RoleCode.BIGBANK_LOAN_SPECIALIST]: {
        'see': [
          'filters',
          'applicationsFilters',
          RouteNames.APPLICATIONS,
          RouteNames.RESIDUALS,
          RouteNames.INDICATIVE_CALCULATOR,
        ],
        'access': [
          RouteNames.APPLICATIONS,
          RouteNames.RESIDUALS,
        ],
        'field': [
          'amlTrainingDate',
        ],
      },

    },
    [Domain.UNDEFINED]: {},
  };
};
