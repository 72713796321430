import { orderBy } from 'lodash-es';
import moment from 'moment';
import { getApplicationStatus } from '@/utils/common/getApplicationStatus';

export function sortDateArray(data: Record<string, any>, key: string) {
  return data.sort((a: Record<string, any>, b: Record<string, any>) => {
    return +formatStringToDate(a[key]) - +formatStringToDate(b[key]);
  });
}

export const formatStringToDate = (date: string, currentFormat = 'DD.MM.YYYY h:mm') => {
  if (!date) {
    return date;
  }

  return moment(date, currentFormat).toDate();
};

export function removeNullProperties(obj: Object) {
  return JSON.parse(
    JSON.stringify(obj, (_key, value) => {
      if (value === null || value === '' || value === undefined || (Array.isArray(value) && value.length === 0)) {
        return undefined;
      }

      return value;
    }),
  );
}

export function sortStringArray(data: Record<string, any>, key: string) {
  return orderBy(removeNullProperties(data), [
    (row) => row[key] ? row[key].toLowerCase() : row[key],
  ]);
}

export function sortStatusArray(data: Record<string, any>) {
  const unsortedData = data.filter((item: Record<string, any>) => {
    return item.applicationStatus = getApplicationStatus(item);
  });

  return orderBy(removeNullProperties(unsortedData), [
    'applicationStatus',
  ]);
}

export function sortNumberArray(data: Record<string, any>, key: string) {
  return data.sort(function (a: Record<string, any>, b: Record<string, any>) {
    return a[key] - b[key];
  });
}
