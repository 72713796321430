import { VueConstructor } from 'vue';
import error from '@/utils/common/error';
import i18n from '@/utils/common/i18n';

export default function installNotification (Vue: VueConstructor) {
  if (Vue) {
    Vue.prototype.$notification = {
      _vm: new Vue({
        data: {
          notificationMessage: null,
          type: null,
        },
      }),
      show (message) {
        const isString = typeof message === 'string';

        return {
          error: () => {
            this._vm.$data.type = 'error';
            this._vm.$data.notificationMessage = isString ? message : pamErrorParser(message);
          },
          alert: () => {
            this._vm.$data.type = 'alert';
            this._vm.$data.notificationMessage = message;
          },
        };
      },
      hide () {
        this._vm.$data.type = null;
        this._vm.$data.notificationMessage = null;
      },
      isActive () {
        return !!this._vm.$data.notificationMessage;
      },
      getMessage () {
        return this._vm.$data.notificationMessage;
      },
      getType () {
        return this._vm.$data.type;
      },
      getErrorText (err) {
        return pamErrorParser(err);
      },
    };
  } else {
    console.error('Vue not found');
  }
}

function pamErrorParser(this, err) {
  if (!err || err.response?.data === undefined) {
    return 'System failure - please try again';
  }

  return err.response.data.fieldErrors
    ? err.response.data.fieldErrors[0].message
    : err.response.data.errors
      ? error(err.response.data.errors[0].code)
      : i18n.tc('pamErrors.systemFailure');
}
