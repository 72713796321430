export enum LtTaskDefinitionKeys {
    SUBMIT_BANK_DETAILS = 'SubmitBankDetails',
    SUBMIT_ADDRESS = 'SubmitAddress',
    VIEW_AND_SIGN_CONTRACT = 'ViewAndSignContract',
    VERIFY_THAT_CONTRACT_IS_SIGNED = 'VerifyThatContractIsSigned',
    GENERATE_CONTRACT = 'generateContract',
    WHATS_NEXT_PAGE_BANK_LINK = 'WhatsNextPageBankLink',
    REMIND_CUSTOMER_TO_SIGN_CONSENT = 'RemindCustomerToSignConsent',
    REVIEW_APPROVED_ADDITIONAL_CHECK = 'ReviewApprovedAdditionalCheck',
    SUBMIT_SPOUSE_INCOMES_AND_LIABILITIES = 'SubmitSpouseIncomesAndLiabilities',
    CHECK_SIGNED_CONTRACT = 'CheckSignedContract',
    UPLOAD_DOCUMENTS = 'UploadDocuments',
    UPLOAD_LEASING_DOCUMENTS = 'UploadLeasingDocuments',
    SHOW_SPOUSE_CONTACT_OR_FULL_DATA_PAGE = 'ShowSpouseContactOrFullDataPage',
    SHOW_LOAN_AND_CONTACT_DATA_INCOMES_AND_LIABILITIES_PAGE = 'ShowLoanAndContactDataIncomesAndLiabilitiesPage',
    SHOW_SPOUSE_INCOMES_AND_LIABILITIES_FIELDS = 'ShowSpouseIncomesAndLiabilitiesFields',
    SHOW_PERSONAL_DATA_PAGE_SPA = 'ShowPersonalDataPageSpa',
    UPLOAD_REFINANCING_DOCUMENTS = 'UploadRefinancingDocuments',
    SUBMIT_SPOUSE_PERSONAL_DATA = 'SubmitSpousePersonalData',
    SUBMIT_SPOUSE_ADDRESS = 'SubmitSpouseAddress',
    SUBMIT_SPOUSE_FINANCE_DATA = 'SubmitSpouseFinanceData',
    UPLOAD_SPOUSE_DOCUMENTS = 'UploadSpouseDocuments',
    ASK_CUSTOMER_TO_INVOLVE_SPOUSE = 'AskCustomerToInvolveSpouse',
    SUBMIT_BANK_AND_ADDRESS_INCLUDING_SPOUSE_ADDRESS = 'SubmitBankAndAddressIncludingSpouseAddress',
    SHOW_LOAN_AND_CONTACT_DATA_INCOMES_AND_LIABILITIES_PAGE_SPOUSE = 'ShowLoanAndContactDataIncomesAndLiabilitiesPageSpouse',
    CONTRACT_SIGNED_THANK_YOU_PAGE = 'ContractSignedThankYouPage',
    UPLOAD_EMPLOYMENT_DOCUMENTS = 'UploadEmploymentDocuments',
    VIEW_AND_SIGN_CONTRACT_CONTENT_LOADER = 'ViewAndSignContractContentLoader',
    ONE_PAGE_SPOUSE_DATA = 'OnePageSpouseData',
    IDENTIFY_AT_PARTNER_STORE = 'IdentifyAtPartnerStore',
    IDENTIFY_AT_PARTNER_STORE_SPOUSE = 'IdentifyAtPartnerStoreSpouse',
    PROVIDE_BAS_VIA_OPEN_BANKING = 'ProvideBasViaOpenBanking',
    SUBMIT_ONLY_SPOUSE_ADDRESS = 'SubmitOnlySpouseAddress',
    SUBMIT_INCOMES_AND_LIABILITIES = 'SubmitIncomesAndLiabilities',
    SPOUSE_INCOMES_AND_LIABILITIES = 'SpouseIncomesAndLiabilities',

    // decision
    READ_UNDECIDED_DECISION = 'ReadUndecidedDecision',
    READ_NEGATIVE_DECISION = 'ReadNegativeDecision',
    SHOW_CONTRACT_SIGNING_NEGATIVE_PAGE = 'ShowContractSigningNegativePage',
    REVIEW_CONDITIONAL_NEGATIVE = 'ReviewConditionalNegative',
    READ_CONDITIONAL_POSITIVE_OFFER = 'ReadConditionalPositiveOffer',
    REVIEW_APPROVED = 'ReviewApproved',
    REVIEW_APPROVED_LEASING = 'ReviewApprovedLeasing',
    REVIEW_APPROVED_FINAL = 'ReviewApprovedFinal',
    REVIEW_APPROVED_UPSALE = 'ReviewApprovedUpsale',
    REVIEW_APPROVED_UPSALE_FINAL = 'ReviewApprovedUpsaleFinal',
    READ_CONDITIONAL_REFINANCE_POSITIVE_OFFER = 'ReadConditionalRefinancePositiveOffer',
    IDENTIFY_ONLINE = 'IdentifyOnline',
    IDENTIFY_WITH_PAYPOST = 'IdentifyWithPaypost',

    // thank you pages
    ANALYSING_DATA = 'AnalysingData',
    DOCUMENTS_UPLOADED_THANK_YOU_PAGE = 'DocumentsUploadedThankYouPage',
    LEASING_DOCUMENTS_UPLOADED_THANK_YOU_PAGE = 'LeasingDocumentsUploadedThankYouPage',
    DOCUMENT_UPLOAD_SKIPPED_THANK_YOU_PAGE = 'DocumentUploadSkippedThankYouPage',
    EMPLOYMENT_DOCUMENTS_UPLOADED_THANK_YOU_PAGE = 'EmploymentDocumentsUploadedThankYouPage',
    DIGITAL_CHOSEN_FOR_CONTRACT_SIGNING_THANK_YOU_PAGE = 'DigitalChosenForContractSigningThankYouPage',
    DIGITAL_CHOSEN_FOR_CONSENT_SIGNING_THANK_YOU_PAGE = 'DigitalChosenForConsentSigningThankYouPage',
    UNEXPECTED_DECISION_ON_CONTRACT_GENERATION_THANK_YOU_PAGE = 'UnexpectedDecisionOnContractGenerationThankYouPage',
    UNEXPECTED_DECISION_ON_PARTNER_CONTRACT_GENERATION_THANK_YOU_PAGE = 'UnexpectedDecisionOnPartnerContractGenerationThankYouPage',
    ERROR_THANK_YOU_PAGE = 'ErrorThankYouPage',
    SIGN_LOAN_TASK = 'signLoanTask',
    SPOUSE_FINANCE_DATA_SUBMITTED_THANK_YOU_PAGE = 'SpouseFinanceDataSubmittedThankYouPage',
    ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE = 'AdditionalDataSubmittedThankYouPage',
    ANALYSING_APPLICATION_THANK_YOU_PAGE = 'AnalysingApplicationThankYouPage',
    UPLOADED_REFINANCING_DOCUMENTS_THANK_YOU_PAGE = 'UploadedRefinancingDocumentsThankYouPage',
    SPOUSE_DOCUMENTS_UPLOADED_THANK_YOU_PAGE = 'SpouseDocumentsUploadedThankYouPage',
    SHOW_NEGATIVE_THANK_YOU_PAGE = 'showNegativeThankYouPage',
    SUBMIT_BANK_ACCOUNT_NUMBER = 'SubmitBankAccountNumber',
}

export enum IdentificationMethod {
    DIGITAL_SIGNING = 'DIGITAL_SIGNING',
    MANUAL = 'MANUAL',
}

export enum UploadChoice {
    UPLOAD_NOW = 'UPLOAD_NOW',
    SEND_SIGNING_LINK = 'SEND_SIGNING_LINK'
}

export enum SigningMethod {
    DIGITAL_SIGNING = 'DIGITAL_SIGNING',
}

export enum CamundaKeys {
    CAMUNDA_PROCESS_PROCESS_DEFINITION_KEY_DOCUMENT_ARCHIVING = 'documents-archiving-flow',
    CAMUNDA_TASK_DEFINITION_KEY_DOCUMENT_ARCHIVING = 'CollectAllManuallySignedDocuments',
    PROCESS_VARIABLE_COLLECTION_EXPIRATION_DEADLINE = 'collectionExpirationDeadline',
    SOMETHING_WRONG_WITH_DOCUMENTS_MESSAGE = 'somethingWrongWithDocumentsMessage',
}

export enum DocumentArchivingErrorTypes {
    FINISHING_ERROR = 'FINISHING_ERROR',
    CONTINUE_LATER_ERROR = 'CONTINUE_LATER_ERROR',
    APPLICATION = 'APPLICATION',
    NOT_FOUND = 'NOT_FOUND',
    PROCESS_CLOSED = 'PROCESS_CLOSED',
    APPLICATION_STATUS_COULD_NOT_BE_LOADED = 'APPLICATION_STATUS_COULD_NOT_BE_LOADED',
    APPLICATION_DEADLINE_COULD_NOT_BE_LOADED = 'APPLICATION_DEADLINE_COULD_NOT_BE_LOADED',
    DOCUMENT_UPLOAD_ERROR = 'DOCUMENT_UPLOAD_ERROR',
    DOCUMENT_DOWNLOAD_ERROR = 'DOCUMENT_DOWNLOAD_ERROR',
    DOCUMENT_METADATA_ERROR = 'DOCUMENT_METADATA_ERROR'
}

export enum DocumentArchivingActionModalTypes {
    CONTINUE_LATER = 'CONTINUE_LATER',
    FINISH_TASK = 'FINISH_TASK',
}

export enum DocumentStatus {
    VALID = 'VALID',
    INVALID = 'INVALID',
    MISSING = 'MISSING',
    WAITING = 'WAITING',
    NOT_REQUIRED = 'NOT_REQUIRED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    INCOMPLETE = 'INCOMPLETE',
}
