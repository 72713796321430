import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from '../../../translations/en.json';
import et from '../../../translations/et.json';
import lt from '../../../translations/lt.json';
import lv from '../../../translations/lv.json';
import fi from '../../../translations/fi.json';
import ru from '../../../translations/ru.json';
import { Domain, Languages } from '@/types/common/enums';

Vue.use(VueI18n);

const messages = Object.assign({
  en,
  lt,
  fi,
  lv,
  et,
  ru,
});

const i18n = new VueI18n({
  locale: localStorage.locale || domainToLanguage(),
  fallbackLocale: Languages.en,
  silentTranslationWarn: true,
  messages,
});

function domainToLanguage () {
  if (process.env.NODE_ENV !== 'production') {
    return Languages.en;
  }

  if (window.config.DOMAIN === Domain.EE) {
    return Languages.et;
  }

  if (window.config.DOMAIN === Domain.INTERNAL) {
    return Languages.en;
  }

  if (window.config.DOMAIN) {
    return window.config.DOMAIN.toLowerCase();
  }

  return Languages.en;
}

export default i18n;
