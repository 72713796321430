import {
  ApplicationStatus,
  CommonTaskDefinitionKeys,
  DocumentType,
  IncomeType,
  LiabilityType,
  LtRequiredDocumentResolution,
  ProductName,
  RouteNames,
  StatusReason,
} from '@/types/common/enums';
import { DocumentStatus, LtTaskDefinitionKeys } from '@/types/lt/enums';
import i18n from '@/utils/common/i18n';

export const ltCountryCode = '+370';

export const allowedFileTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.etsi.asic-e+zip',
  'application/x-secureoffice-adoc',
  'application/vnd.lt.archyvai.adoc-2008',
];

export const bankDetailsEligibleProducts = [
  ProductName.SMALL_LOAN,
  ProductName.CAR_LOAN,
  ProductName.RENOVATION_LOAN,
  ProductName.MEDICAL_LOAN,
  ProductName.REFINANCING_LOAN,
];

export const incomeTypes = [
  {
    value: IncomeType.SALARY,
    text: i18n.tc('incomeTypes.salary'),
  },
  {
    value: IncomeType.PARENTAL_BENEFIT,
    text: i18n.tc('incomeTypes.parentalBenefit'),
  },
  {
    value: IncomeType.CIVIL_SERVANT,
    text: i18n.tc('incomeTypes.civilServant'),
  },
  {
    value: IncomeType.BUSINESS,
    text: i18n.tc('incomeTypes.business'),
  },
  {
    value: IncomeType.SELF_EMPLOYED,
    text: i18n.tc('incomeTypes.selfEmployed'),
  },
  {
    value: IncomeType.RENT,
    text: i18n.tc('incomeTypes.rent'),
  },
  {
    value: IncomeType.PENSION,
    text: i18n.tc('incomeTypes.pension'),
  },
  {
    value: IncomeType.FOREIGN_SALARY,
    text: i18n.tc('incomeTypes.foreignSalary'),
  },
  {
    value: IncomeType.DAILY_ALLOWANCES,
    text: i18n.tc('incomeTypes.dailyAllowances'),
  },
  {
    value: IncomeType.SPORT_OR_PERFORMANCE,
    text: i18n.tc('incomeTypes.sportOrPerformance'),
  },
  {
    value: IncomeType.AGRICULTURE,
    text: i18n.tc('incomeTypes.agriculture'),
  },
];

export const liabilitiesTypes = [
  {
    value: LiabilityType.MORTGAGE,
    text: i18n.tc('lt.liabilities.mortgage'),
  },
  {
    value: LiabilityType.CONSUMER_LOAN,
    text: i18n.tc('lt.liabilities.consumerLoan'),
  },
  {
    value: LiabilityType.OTHER,
    text: i18n.tc('lt.liabilities.other'),
  },
];

export const incomeTypesGroups = () => [
  {
    text: i18n.tc('lt.incomeGroups.mainIncomes'),
    options: [
      {
        value: 'SALARY',
        text: i18n.tc('lt.incomeGroups.salary'),
      },
      {
        value: 'PARENTAL_BENEFIT',
        text: i18n.tc('lt.incomeGroups.parentalBenefit'),
      },
      {
        value: 'CIVIL_SERVANT',
        text: i18n.tc('lt.incomeGroups.civilServant'),
      },
      {
        value: 'BUSINESS',
        text: i18n.tc('lt.incomeGroups.business'),
      },
      {
        value: 'SELF_EMPLOYED',
        text: i18n.tc('lt.incomeGroups.selfEmployed'),
      },
      {
        value: 'PENSION',
        text: i18n.tc('lt.incomeGroups.pension'),
      },
      {
        value: 'FOREIGN_SALARY',
        text: i18n.tc('lt.incomeGroups.foreignSalary'),
      },
      {
        value: 'SPORT_OR_PERFORMANCE',
        text: i18n.tc('lt.incomeGroups.sportOrPerformance'),
      },
    ],
  },
  {
    text: i18n.tc('lt.incomeGroups.additionalIncomes'),
    options: [
      {
        value: 'RENT',
        text: i18n.tc('lt.incomeGroups.rent'),
      },
      {
        value: 'DAILY_ALLOWANCES',
        text: i18n.tc('lt.incomeGroups.dailyAllowances'),
      },
    ],
  },
];

export const phoneSupportedCountries =
  [
    {
      alpha2: 'LT',
      callingCode: '+370',
      name: i18n.tc('Lithuania'),
    },
    {
      alpha2: 'IE',
      callingCode: '+353',
      name: i18n.tc('Ireland'),
    },
    {
      alpha2: 'DK',
      callingCode: '+45',
      name: i18n.tc('Denmark'),
    },
    {
      alpha2: 'EE',
      callingCode: '+372',
      name: i18n.tc('Estonia'),
    },
    {
      alpha2: 'GB',
      callingCode: '+44',
      name: i18n.tc('United Kingdom'),
    },
    {
      alpha2: 'LV',
      callingCode: '+371',
      name: i18n.tc('Latvia'),
    },
    {
      alpha2: 'NO',
      callingCode: '+47',
      name: i18n.tc('Norway'),
    },
    {
      alpha2: 'NL',
      callingCode: '+31',
      name: i18n.tc('Netherlands'),
    },
    {
      alpha2: 'FR',
      callingCode: '+33',
      name: i18n.tc('France'),
    },
    {
      alpha2: 'FI',
      callingCode: '+358',
      name: i18n.tc('Finland'),
    },
    {
      alpha2: 'SE',
      callingCode: '+46',
      name: i18n.tc('Sweden'),
    },
    {
      alpha2: 'DE',
      callingCode: '+49',
      name: i18n.tc('Germany'),
    },
  ];

export const redirectedRoutes = [
  RouteNames.ANALYSING_DATA,
  RouteNames.ERROR,
  RouteNames.CONTRACT_SIGNED_THANK_YOU_PAGE,
  RouteNames.DIGITAL_CHOSEN_FOR_CONTRACT_THANK_YOU_PAGE,
  RouteNames.SIGN_LOAN_TASK,
];

export const tasksWhereChannelSelectCanBeVisible = [
  LtTaskDefinitionKeys.VERIFY_THAT_CONTRACT_IS_SIGNED,
  LtTaskDefinitionKeys.SIGN_LOAN_TASK,
  CommonTaskDefinitionKeys.NEW_PARTNER_APPLICATION,
  CommonTaskDefinitionKeys.NEW_REMOTE_APPLICATION,
  CommonTaskDefinitionKeys.NEW_SHORT_APPLICATION,
];

export const decisionPages = [
  LtTaskDefinitionKeys.READ_UNDECIDED_DECISION,
  LtTaskDefinitionKeys.READ_NEGATIVE_DECISION,
  LtTaskDefinitionKeys.CONTRACT_SIGNED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.DOCUMENT_UPLOAD_SKIPPED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.DOCUMENTS_UPLOADED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.LEASING_DOCUMENTS_UPLOADED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.DIGITAL_CHOSEN_FOR_CONTRACT_SIGNING_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.IDENTIFY_ONLINE,
  LtTaskDefinitionKeys.ERROR_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.ANALYSING_DATA,
];

export const offerSteps = [
  LtTaskDefinitionKeys.READ_CONDITIONAL_POSITIVE_OFFER,
  LtTaskDefinitionKeys.READ_CONDITIONAL_REFINANCE_POSITIVE_OFFER,
  LtTaskDefinitionKeys.REVIEW_APPROVED_UPSALE,
  LtTaskDefinitionKeys.REVIEW_APPROVED_UPSALE_FINAL,
  LtTaskDefinitionKeys.REVIEW_APPROVED,
  LtTaskDefinitionKeys.REVIEW_APPROVED_LEASING,
  LtTaskDefinitionKeys.REVIEW_APPROVED_FINAL,
];

export const resolutionsWhichAreNotEligibleForUpload = [
  LtRequiredDocumentResolution.VALID,
  LtRequiredDocumentResolution.NOT_REQUIRED,
  LtRequiredDocumentResolution.CANNOT_PROVIDE,
];

export const continueLaterSteps = [
  LtTaskDefinitionKeys.SHOW_SPOUSE_CONTACT_OR_FULL_DATA_PAGE,
  LtTaskDefinitionKeys.SHOW_LOAN_AND_CONTACT_DATA_INCOMES_AND_LIABILITIES_PAGE,
  LtTaskDefinitionKeys.SHOW_LOAN_AND_CONTACT_DATA_INCOMES_AND_LIABILITIES_PAGE_SPOUSE,
  LtTaskDefinitionKeys.SHOW_SPOUSE_INCOMES_AND_LIABILITIES_FIELDS,
  LtTaskDefinitionKeys.REVIEW_APPROVED,
  LtTaskDefinitionKeys.REVIEW_APPROVED_FINAL,
  LtTaskDefinitionKeys.READ_CONDITIONAL_POSITIVE_OFFER,
  LtTaskDefinitionKeys.REVIEW_CONDITIONAL_NEGATIVE,
  LtTaskDefinitionKeys.SUBMIT_BANK_DETAILS,
  LtTaskDefinitionKeys.SUBMIT_BANK_AND_ADDRESS_INCLUDING_SPOUSE_ADDRESS,
];

export const tasksWithIdCopyUpload = [
  CommonTaskDefinitionKeys.NEW_PARTNER_APPLICATION,
  LtTaskDefinitionKeys.SHOW_SPOUSE_CONTACT_OR_FULL_DATA_PAGE,
  LtTaskDefinitionKeys.READ_CONDITIONAL_POSITIVE_OFFER,
  LtTaskDefinitionKeys.REVIEW_CONDITIONAL_NEGATIVE,
  LtTaskDefinitionKeys.READ_CONDITIONAL_REFINANCE_POSITIVE_OFFER,
  LtTaskDefinitionKeys.VERIFY_THAT_CONTRACT_IS_SIGNED,
];

export const tasksWithIdCopyUploadSpouse = [
  LtTaskDefinitionKeys.SHOW_SPOUSE_CONTACT_OR_FULL_DATA_PAGE,
  LtTaskDefinitionKeys.READ_CONDITIONAL_POSITIVE_OFFER,
  LtTaskDefinitionKeys.REVIEW_CONDITIONAL_NEGATIVE,
  LtTaskDefinitionKeys.READ_CONDITIONAL_REFINANCE_POSITIVE_OFFER,
  LtTaskDefinitionKeys.IDENTIFY_AT_PARTNER_STORE_SPOUSE,
  LtTaskDefinitionKeys.ASK_CUSTOMER_TO_INVOLVE_SPOUSE,
];

export const tasksWithConsentNeeded = [
  CommonTaskDefinitionKeys.NEW_PARTNER_APPLICATION,
];

export const uploadDocumentsRoutes = [
  LtTaskDefinitionKeys.UPLOAD_DOCUMENTS,
  LtTaskDefinitionKeys.UPLOAD_LEASING_DOCUMENTS,
  LtTaskDefinitionKeys.UPLOAD_SPOUSE_DOCUMENTS,
  LtTaskDefinitionKeys.UPLOAD_REFINANCING_DOCUMENTS,
  LtTaskDefinitionKeys.UPLOAD_EMPLOYMENT_DOCUMENTS,
];

export const analyzingDataSteps = [
  LtTaskDefinitionKeys.SPOUSE_FINANCE_DATA_SUBMITTED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.ANALYSING_APPLICATION_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.UNEXPECTED_DECISION_ON_CONTRACT_GENERATION_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.UNEXPECTED_DECISION_ON_PARTNER_CONTRACT_GENERATION_THANK_YOU_PAGE,
];

export const onePageSpouseSteps = [
  LtTaskDefinitionKeys.SUBMIT_SPOUSE_PERSONAL_DATA,
  LtTaskDefinitionKeys.SUBMIT_SPOUSE_ADDRESS,
  LtTaskDefinitionKeys.SUBMIT_SPOUSE_FINANCE_DATA,
];

export const documentsUploadedThankYouSteps = [
  LtTaskDefinitionKeys.DOCUMENTS_UPLOADED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.LEASING_DOCUMENTS_UPLOADED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.EMPLOYMENT_DOCUMENTS_UPLOADED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.UPLOADED_REFINANCING_DOCUMENTS_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.SPOUSE_DOCUMENTS_UPLOADED_THANK_YOU_PAGE,
];

export const rejectedDecisionSteps = [
  LtTaskDefinitionKeys.READ_NEGATIVE_DECISION,
  LtTaskDefinitionKeys.SHOW_NEGATIVE_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.SHOW_CONTRACT_SIGNING_NEGATIVE_PAGE,
];

export const annulledReasons = [
  StatusReason.PARTNER_LOAN_TAKEN,
  StatusReason.BETTER_OFFER_ELSEWHERE,
  StatusReason.COMMISSION_TOO_HIGH,
  StatusReason.REFUSED_DEBT_REPAYMENT,
  StatusReason.CANNOT_PROVIDE_REQUIRED_DOCUMENTS,
  StatusReason.FINANCIAL_SITUATION_IMPROVED,
  StatusReason.NO_ANSWER,
  StatusReason.NO_CONSENT,
  StatusReason.NO_NEED,
  StatusReason.APPLICANT_IS_UNREACHABLE,
  StatusReason.RECEIVED_LOAN_FROM_OTHER_SOURCE,
  StatusReason.MONTHLY_PAYMENT_TOO_HIGH,
  StatusReason.LOAN_PERIOD_TOO_LONG,
  StatusReason.RATE_TOO_HIGH,
  StatusReason.CANNOT_SIGN_CONTRACT,
  StatusReason.OFFERED_SUM_TOO_SMALL,
  StatusReason.CANNOT_PROVIDE_COBORROWER,
  StatusReason.WAITED_TOO_LONG_FOR_OFFER,
  StatusReason.APPLICATION_EXPIRED,
  StatusReason.DOUBLE_APPLICATION,
  StatusReason.NOT_INTERESTED_IN_REFINANCING,
];

export const applicationStateClosedStatus = [
  ApplicationStatus.COMPLETE,
  ApplicationStatus.MANUAL_PROCEEDING,
  ApplicationStatus.ANNULLED,
  ApplicationStatus.EXPIRED,
];

export const contractRelatedDocumentTypes = [
  DocumentType.SIGNED_CONTRACT,
  DocumentType.DEED_OF_DELIVERY,
  DocumentType.PURCHASE_AGREEMENT,
  DocumentType.SIGNED_CONTRACT_CHANGE,
];

export const documentArchiveEligibleStatusTypes = [
  DocumentStatus.WAITING,
  DocumentStatus.IN_PROGRESS,
];

export const allowedContentTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
];
