export const countryUserGroups = [
  {
    value: 'EEBIG',
    userGroup: 'nest_estonia',
    text: 'Estonia',
    code: 'ee',
  },
  {
    value: 'LVBIG',
    userGroup: 'nest_latvia',
    text: 'Latvia',
    code: 'lv',
  },
  {
    value: 'LTBIG',
    userGroup: 'nest_lithuania',
    text: 'Lithuania',
    code: 'lt',
  },
  {
    value: 'FIBIG',
    userGroup: 'nest_finland',
    text: 'Finland',
    code: 'fi',
  },
];
