import { personalCodeControlCharacters } from '@/constants/fi/constants';

export function validatePersonalCodeFinland (value) {
  if (value.length < 11) {
    return false;
  }

  const validFormat = /^[0-9]{2}[01][0-9]{3}[-+A][0-9]{3}[A-Z,0-9]$/.exec(value) !== null;
  const remainder = parseInt(value.substring(0, 6) + value.substring(7, 10)) % 31;
  const controlCharacterMatches = personalCodeControlCharacters[remainder] === value.substring(10, 11);

  return validFormat && controlCharacterMatches;
}
