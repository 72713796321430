import Iban from 'iban';
import Validator from 'validator';
import { validateAgeLatvia, validatePersonalCodeLatvia } from '@/validation/common/personalCodeValidatorLatvia';
import { validatePersonalCodeLithuaniaEstonia } from '@/validation/common/personalCodeValidatorLithuaniaEstonia';
import { offerDecisions, fiCountryCode } from '@/constants/fi/constants';
import { CommonTaskDefinitionKeys, DocumentType, Domain, IdDocumentType } from '@/types/common/enums';
import dayjs from 'dayjs';
import moment from 'moment-mini';
import { getSelectedDomain } from '@/utils/common/getSelectedDomain';
import { validatePersonalCodeFinland } from '@/validation/common/validatePersonalCodeFinland';
import { ltCountryCode, phoneSupportedCountries as ltPhoneSupportedCountries } from '@/constants/lt/constants';
import { eeCountryCode, eePhoneSupportedCountries } from '@/constants/ee/constants';
import { validateCharactersLv } from '@/validation/common/validateCharactersLv';

const personalCodeLV = {
  name: 'personalCodeLV',
  validator: validatePersonalCodeLatvia,
};

const maxDownPayment = {
  name: 'maxDownPayment',
  validator:  (value, args) => {
    return args[1] ? (+args[1] - +value) >= +args[0] : true;
  },
};

const minDownPayment = {
  name: 'minDownPayment',
  validator:  (value, args) => {
    if (+args[0] >= 1400 && +value < (+args[0] * 0.1)) {
      return false;
    }

    return true;
  },
};

const personalCodeAgeLV = {
  name: 'personalCodeAgeLV',
  validator: validateAgeLatvia,
};

const dateOfBirthLV = {
  name: 'dateOfBirthLV',
  validator: (value) => {
    const rule = /(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})$/;

    return rule.exec(value) != null;
  },
};

const ageLV = {
  name: 'ageLV',
  validator: (value) => {
    const years = moment().diff(moment(value, 'DDMMYYYY'), 'years');
    const months = moment().diff(moment(value, 'DDMMYYYY'), 'months') % 12;

    return years >= 19 && (years <= 73 && months <= 11);
  },
};

const addressLV = {
  name: 'addressLV',
  validator: (value) => {
    const rule = /^[a-zA-ZāčēģīķļņštūžĀČĒĢĪĶĻŅŠTŪŽⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅪⅫⅬⅭⅮⅯ0-9\s."#(),’'„“‟”\/«»-]*$/;

    return rule.exec(value) != null;
  },
};

const alphaLV = {
  name: 'alphaLV',
  validator: (value) => {
    return /^[A-ZĀČĒĢĪĶĻŅŠŪŽ\s-]*$/i.test(value) && !value.startsWith('-') && !value.endsWith('-');
  },
};

const alphaNumLV = {
  name: 'alphaNumLV',
  validator: (value) => {
    const rule = /^[a-zA-ZāčēģīķļņšūžĀČĒĢĪĶĻŅŠŪŽ\d]*$/i;

    return rule.exec(value) != null;
  },
};

const charactersLV = {
  name: 'charactersLV',
  validator: (value) => {
    return validateCharactersLv(value);
  },
};

const personalCodeDoesNotMatchLoggedInUser = {
  name: 'personalCodeDoesNotMatchLoggedInUser',
  validator: (value) => {
    const user = JSON.parse(localStorage.user);

    return value !== user.personalCode;
  },
};

const selfFinancingAmountFI = {
  name: 'selfFinancingAmountFI',
  validator: (downpayment, data) => {
    const tradeInPrice = parseFloat(data[0]);
    const tradeInLoanAmount = parseFloat(data[1]);
    const decision = data[2];
    downpayment = parseFloat(downpayment);

    if (decision === offerDecisions.APPROVED || decision === offerDecisions.APPROVED_ADDITIONAL_CHECK) {
      if (downpayment > 0) {
        return downpayment + tradeInPrice - tradeInLoanAmount >= 0;
      }
    }

    return downpayment >= tradeInLoanAmount - tradeInPrice;
  },
};

const personalCodeEE = {
  name: 'personalCodeEE',
  validator: validatePersonalCodeLithuaniaEstonia,
};

const personalCodeLT = {
  name: 'personalCodeLT',
  validator: validatePersonalCodeLithuaniaEstonia,
};

const personalCodeFI = {
  name: 'personalCodeFI',
  validator: value => {
    return validatePersonalCodeFinland(value);
  },
};

const coApplicantPersonalCodeMatchingFI = {
  name: 'coApplicantPersonalCodeMatchingFI',
  validator: (value: string, [
    applicantPersonalId]: string[
]) => {
    return value !== applicantPersonalId;
  },
};

const ibanLT = {
  name: 'ibanLT',
  validator: {
    validate: (value) => Iban.isValid(value) && Validator.isWhitelisted(value, '1234567890LlTt- '),
  },
};

const ibanLV = {
  name: 'ibanLV',
  validator: {
    validate: (value) => Iban.isValid(value) && value.toUpperCase().startsWith('LV'),
  },
};

const documentNumberLV = {
  name: 'documentNumberLV',
  validator: {
    validate: (value) => /^[A-Za-z0-9]{1,}$/.exec(value) !== null,
  },
};

const idCardLV = {
  name: 'idCardLV',
  validator: {
    validate: (value) => /^[P|N]A[0-9]{7}$/.exec(value) !== null,
  },
};

const passportLV = {
  name: 'passportLV',
  validator: {
    validate: (value) => /^[L|N][A-Z][0-9]{7}$/.exec(value) !== null,
  },
};

const ibanFI = {
  name: 'ibanFI',
  validator: {
    validate: (value) => Iban.isValid(value) && !!value.match(/^(FI\d{2})(\d{6})(\d{7})(\d{1})$/i),
  },
};

const ibanEE = {
  name: 'ibanEE',
  validator: {
    validate: (value) => Iban.isValid(value) && Validator.isWhitelisted(value, '1234567890Ee- '),
  },
};

const iban = {
  name: 'iban',
  validator: {
    validate: (value) => ibanEE.validator.validate(value) ||
        ibanLT.validator.validate(value) ||
        ibanLV.validator.validate(value) ||
        ibanFI.validator.validate(value),
  },
};

const price = {
  name: 'price',
  validator: {
    validate: (value) => /^[0-9]+([.,][0-9]{1,2})?$/.test(value),
  },
};

const positive = {
  name: 'positive',
  validator: {
    validate: (value) => value ? parseFloat(value.replace(',', '.')) >= 0 : false,
  },
};

const phone = {
  name: 'phone',
  validator: {
    validate: (value) => {
      const standardizedValue = value.replace(' ', '');
      const isMobile = /^(3725|3584|35850)/;
      const digits = /^\d+$/;
      const mobileValidator = /^((3725|3584)\d{4,11}|35850\d{4,10})$/;
      const landlineValidator = /^((372|358|371|370|380)\d{4,12}|(44|45|46|47|49)\d{4,13}|7\d{4,14})$/;
      if (!digits.test(standardizedValue)) {
        return false;
      }

      return !isMobile.test(standardizedValue) && landlineValidator.test(standardizedValue) || mobileValidator.test(standardizedValue);
    },
  },
};

const finance = {
  name: 'finance',
  validator: {
    validate: (value) => /^[0-9 ]+[0-9]*[ ]?[,.]?[0-9 ]*$/.test(value),
  },
};

const url = {
  name: 'url',
  validator: {
    validate: (value) => Validator.isURL(value),
  },
};

const name = {
  name: 'name',
  validator: {
    validate: (value) => /^[A-Za-z\u00C0-\u1FFF\u2800-\uFFFD\s'-]*$/.exec(value) !== null,
  },
};

const mobilePhoneLT = {
  name: 'mobilePhoneLT',
  validator: {
    validate: (value) => {
      const rule = /^(\+370)?\s?((6)[0-9]{7})$/;

      return rule.exec(value) != null;
    },
  },
};

const mobilePhoneLV = {
  name: 'mobilePhoneLV',
  validator: {
    validate: (value) => {
      const rule = /^(\+371)?\s?((2)[0-9]{7})$/;

      return rule.exec(value) != null;
    },
  },
};

const mobilePhoneEE = {
  name: 'mobilePhoneEE',
  validator: {
    validate: (value) => {
      const rule = /^(\+372)?\s?((5)[0-9]{6,7})_?$/;

      return rule.exec(value) != null;
    },
  },
};

const mobilePhoneFI = {
  name: 'mobilePhoneFI',
  validator: {
    validate: (value) => {
      const rule = /^(\+358)?\s?((4[0-9]|50)\s?(\d\s?){4,8})_{0,4}$/;

      return rule.exec(value) != null;
    },
  },
};

const phoneLT = {
  name: 'phoneLT',
  validator: {
    validate: (value) => {
      const rule = /^(\+370)?\s?([56][0-9]{7}|(31|34|37|38|41|42|44|45|46)[0-9]{6})$/;

      return rule.exec(value) != null;
    },
  },
};

const phoneEE = {
  name: 'phoneEE',
  validator: {
    validate: (value) => {
      const rule = /^(\+372)?\s?([0-9]{7,8})_?$/;

      return rule.exec(value) != null;
    },
  },
};

const phoneFI = {
  name: 'phoneFI',
  validator: {
    validate: (value) => {
      value = value.replaceAll('_', '');
      const rule = /^(\+358\s?)(?!(100|20(0|2(0|[2-3])|9[8-9])|300|600|700|708|75(00[0-3]|(1|2)"+"\d{2}|30[0-2]|32[0-2]|75[0-2]|98[0-2])))(4|50|10[1-9]|20(1|2(1|[4-9])"+"|[3-9])|29|30[1-9]|71|73|75(00[3-9]|30[3-9]|32[3-9]|53[3-9]|83[3-9])"+"|2|3|5|6|8|9|1[3-9])\s?(\d\s?){4,19}\d$/;

      return rule.exec(value) != null;
    },
  },
};

const phoneLV = {
  name: 'phoneLV',
  validator: {
    validate: (value) => {
      const rule = /^(\+371)?\s?([2689][0-9]{7})$/;

      return rule.exec(value) != null;
    },
  },
};

const passwordUppercase = {
  name: 'passwordUppercase',
  validator: {
    validate: (value) => {
      return new RegExp('^(?=.*[A-ZÖÄÜÕŠŽĄČĘĖĮŲŪ])').test(value);
    },
  },
};

const passwordLowercase = {
  name: 'passwordLowercase',
  validator: {
    validate: (value) => {
      return new RegExp('^(?=.*[a-zöäüõšžąčęėįųū])').test(value);
    },
  },
};

const passwordCharacters = {
  name: 'passwordCharacters',
  validator: {
    validate: (value) => {
      return new RegExp('^(?=.*[0-9])(?=.*[-!"#$%&\'()*+,./:;€£<=>?@[\\]^_`{|}~])(?=.{8,})').test(value);
    },
  },
};

const usernameCharacters = {
  name: 'usernameCharacters',
  validator: {
    validate: (value) => {
      return new RegExp(/@(?=.*[!"#$%&'()*+,/:;€£<=>?@[\]^_`{|}~])|^(?!\.)(?!.*\.$)(?!.*?\.\.)[-a-zöäüõA-ZÖÄÜÕ0-9_.@]+$/).test(value);
    },
  },
};

const usernameUppercase = {
  name: 'usernameUppercase',
  validator: {
    validate: (value) => {
      return !new RegExp('^(?=.*[A-ZÖÄÜÕŠŽĄČĘĖĮŲŪ])').test(value);
    },
  },
};

const password = {
  name: 'password',
  validator: {
    validate: (value, arr) => {
      return !stringValidator(value, arr);
    },
  },
};

const amount = {
  name: 'amount',
  validator: {
    validate: (value) => {
      return amountValidator(value);
    },
  },
};

const postCodeLT = {
  name: 'postCodeLT',
  validator: {
    validate: (value) => {
      const rule = /^LT-\d{5}$/;

      return rule.exec(value) != null;
    },
  },
};

const postCodeLV = {
  name: 'postCodeLV',
  validator: {
    validate: (value) => {
      const rule = /^LV-\d{4}$/;

      return rule.exec(value) != null;
    },
  },
};

const postCodeFI = {
  name: 'postCodeFI',
  validator: {
    validate: (value) => {
      const rule = /^\d{5}$/;

      return rule.exec(value) != null;
    },
  },
};

const postCodeEE = {
  name: 'postCodeEE',
  validator: {
    validate: (value) => {
      const rule = /^\d{5}$/;

      return rule.exec(value) != null;
    },
  },
};

const carRegistrationNumberFI = {
  name: 'carRegistrationNumberFI',
  validator: {
    validate: value => !!value.match(/^[A-ZÄÅÖ]{1,3}-[0-9]{1,5}$/),
  },
};

const motorcycleRegistrationNumberFI = {
  name: 'motorcycleRegistrationNumberFI',
  validator: {
    validate: value =>
      !!value.match(
        /^(?![A-ZÄÅÖ]{1,2}-[A-ZÄÅÖ]{1,3})[A-ZÄÅÖ0-9]{1,2}-[A-ZÄÅÖ0-9]{1,3}$/,
      ),
  },
};

const specialRegistrationNumberFI = {
  name: 'specialRegistrationNumberFI',
  validator: {
    validate: value =>
      !!value.match(
        /^(?![A-ZÄÅÖ]{1,3}-[A-ZÄÅÖ]{1,3})[A-ZÄÅÖ0-9]{1,3}-[A-ZÄÅÖ0-9]{1,3}$/,
      ),
  },
};

const boatRegistrationNumberFI = {
  name: 'boatRegistrationNumberFI',
  validator: {
    validate: value => !!value.match(/^[A-ZÄÅÖ]{1}[0-9]{1,7}$/),
  },
};

const isNotCollateralRegNumberFI = {
  name: 'isNotCollateralRegNumberFI',
  validator: {
    validate: (value, data) => {
      return value !== data[0];
    },
  },
};

export const vinNumberFI = {
  name: 'vinNumberFI',
  validator: {
    validate: value => value.length >= 2 && value.length <= 18 && new RegExp('^\\s*[a-zA-Z0-9-]*\\s*$').test(value),
  },
};

export const vinNumberBoatsFI = {
  name: 'vinNumberBoatsFI',
  validator: {
    validate: value => value.length >= 2 && value.length <= 17 && new RegExp('^\\s*[a-zA-Z0-9-]*\\s*$').test(value),
  },
};

const isNotCollateralVinFI = {
  name: 'isNotCollateralVinFI',
  validator: {
    validate: (value, data) => {
      return value !== data[0];
    },
  },
};

const boatModelYearFI = {
  name: 'boatModelYearFI',
  validator: {
    validate: value => !!String(value).match(/^(19|20)\d{2}$/),
  },
};

const customerIdentificationFI = {
  name: 'customerIdentificationFI',
  validator: {
    validate: (documentType,
      [
        taskDefinitionKey,
        offerChanged,
        idDocument,
      ]) => {

      if (taskDefinitionKey === CommonTaskDefinitionKeys.NEW_PARTNER_APPLICATION || offerChanged !== 'false' && !idDocument[0].number) {
        return true;
      }

      return documentType !== IdDocumentType.UNMAPPED;
    },
  },
};

const hasValidLicenseFI = {
  name: 'hasValidLicenseFI',
  validator: {
    validate: (value) => {
      return value !== 'false';
    },
  },
};

const isNotPartnerIdFI = {
  name: 'isNotPartnerIdFI',
  validator: {
    validate: (value, data) => {
      return value !== data[0];
    },
  },
};

const todayOrInFutureFI = {
  name: 'todayOrInFutureFI',
  validator: {
    validate: (value) => {
      return value >= new Date().setHours(0, 0, 0, 0);
    },
  },
};

const simplifiedEmailFI = {
  name: 'simplifiedEmailFI',
  validator: {
    validate: value => !!value.match(/^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+_?)*)((|\-)*)@([A-Za-z0-9_]+)(([\.\-]?[a-zA-Z0-9_]+)*)\.([A-Za-z]{2,})$/),
  },
};

const allowRegistryChecksFI = {
  name: 'allowRegistryChecksFI',
  validator: {
    validate: (value) => {
      return value;
    },
  },
};

const registrationCodeFi = {
  name: 'registrationCodeFi',
  validator: {
    validate: value => !!value.match(/^[0-9]{7}-[0-9]$/),
  },
};

const monthYearFI = {
  name: 'monthYearFI',
  validator: {
    validate: (value: string, [
      type]: string[
    ]) => {
      const today = dayjs();
      const month = Number(value.split('.')[0]);
      const year = Number(value.split('.')[1]);
      const minimumYear = type === 'employment' ? 1950 : 1900;

      return dayjs(value, 'MM.YYYY').isBefore(today) && month >= 1 && month <= 12 && year >= minimumYear;
    },
  },
};

const uploadedFilesAreValid = {
  name: 'uploadedFilesAreValid',
  validator: {
    validate: files => !files.some(file => file.errorFileName),

  },
};

const commonPhoneRequired = {
  name: 'commonPhoneRequired',
  validator: {
    validate: (value ) => {
      const normalizedValue = value.replace(' ', '');
      const domain = getSelectedDomain();
      const regionPhoneSupportedCountries = domain === Domain.EE ? eePhoneSupportedCountries : ltPhoneSupportedCountries;
      const prefix = regionPhoneSupportedCountries.map((country) => country.callingCode).find(prefix => normalizedValue.startsWith(prefix));
      const suffix = normalizedValue.split(prefix)[1];

      return typeof suffix === 'string' && suffix.length > 0;
    },
  },
};

const commonPhoneNumber = {
  name: 'commonPhoneNumber',
  validator: {
    validate: value => {
      const domain = getSelectedDomain();
      const regionPhoneSupportedCountries = domain === Domain.EE ? eePhoneSupportedCountries : ltPhoneSupportedCountries;
      const LT_MOBILE_NUMBER_RULE = new RegExp(`^\\${ ltCountryCode }([6][0-9]{7})$`);
      const EE_AND_FI_MOBILE_NUMBER_RULE = new RegExp(`^((\\${eeCountryCode}5|\\${fiCountryCode}4)\\d{4,11}|\\${fiCountryCode}50\\d{4,10})$`);
      const FOREIGN_MOBILE_NUMBER_RULE = new RegExp(`^(?!\\${ ltCountryCode || fiCountryCode || eeCountryCode })(\\+\\d{10,15})$`);

      const mobileNumberMinLength = 11;
      const mobileNumberMaxLength = 16;

      const isValidForeignMobile = normalizedValue => {
        return FOREIGN_MOBILE_NUMBER_RULE.test(normalizedValue) &&
            normalizedValue.length >= mobileNumberMinLength &&
            normalizedValue.length <= mobileNumberMaxLength;
      };

      const normalizedValue = value.replace(' ', '');

      if (normalizedValue.startsWith(ltCountryCode)) {
        return LT_MOBILE_NUMBER_RULE.test(normalizedValue);
      }

      if (normalizedValue.startsWith(fiCountryCode) || normalizedValue.startsWith(eeCountryCode)) {
        return EE_AND_FI_MOBILE_NUMBER_RULE.test(normalizedValue);
      }

      if (regionPhoneSupportedCountries.map((country) => country.callingCode).some(code => code !== ltCountryCode && normalizedValue.startsWith(code))) {
        return isValidForeignMobile(normalizedValue);
      }

      return false;
    },
  },
};

const documentStatusOption = {
  name: 'documentStatusOption',
  validator: {
    validate: (value, options) => !!options.find(option => option.value === value && !option.disabled),
  },
};

const idDocumentCopyAndConsentAreValidLT = {
  name: 'idDocumentCopyAndConsentAreValidLT',
  validator: {
    validate: files => {
      return !files.some(file => file.errorFileName) &&
          files.some(file => file.section === DocumentType.ID_PHOTO_FRONT) &&
          files.some(file => file.section === DocumentType.SIGNED_CONSENT);
    },
  },
};

const spouseIsNotApplicantLT = {
  name: 'spouseIsNotApplicantLT',
  validator: {
    validate: (spouseFirstName,
      [
        spouseSurname,
        applicantFirstName,
        applicantSurname,
      ]) => {
      const LETTERS = {
        Ą: 'A',
        Č: 'C',
        Ę: 'E',
        Ė: 'E',
        Į: 'I',
        Š: 'S',
        Ų: 'U',
        Ū: 'U',
        Ž: 'Z',
      };

      function normalize(name) {
        return name
          .toUpperCase()
          .replace(/[ĄČĘĖĮŠŲŪŽ]/g, letter => LETTERS[letter]);
      }

      const applicantFullName = normalize(
        `${applicantFirstName} ${applicantSurname}`,
      );
      const spouseFullName = normalize(`${spouseFirstName} ${spouseSurname}`);

      return applicantFullName !== spouseFullName;
    },
  },
};

const commonEmail = {
  name: 'commonEmail',
  validator: {
    validate: value => {
      const commonTypos = /(\.@)|\@(.*gemail)|\.(rr|gom|ry|cim|vom|eee|ri|con|como)$/;
      const emailBase = /^['_A-Za-z0-9-&+]+(\.['_A-Za-z0-9-&+]+)*[.]{0,1}@([A-Za-z0-9-])+(\.[A-Za-z0-9-]+)*((\.[A-Za-z0-9]{2,})|(\.[A-Za-z0-9]{2,}\.[A-Za-z0-9]{2,}))$/;

      return emailBase.test(value) && !commonTypos.test(value);
    },
  },
};

const numDash = {
  name: 'numDash',
  validator: {
    validate: (value) => {
      const rule = /^([0-9]|[0-9]-)*$/;

      return rule.exec(value) != null;
    },
  },
};

const employerNameFI = {
  name: 'employerNameFI',
  validator: {
    validate: value => !value.match(/['\\\/]/),
  },
};

const minNoSpaces = {
  name: 'minNoSpaces',
  validator: {
    validate: (value, data) => {
      return value && value.trim().length >= data[0];
    },
  },
};

const commonRules = [
  iban,
  price,
  positive,
  phone,
  finance,
  url,
  name,
  passwordUppercase,
  passwordLowercase,
  passwordCharacters,
  usernameCharacters,
  usernameUppercase,
  password,
  amount,
  uploadedFilesAreValid,
  maxDownPayment,
  minDownPayment,
  personalCodeDoesNotMatchLoggedInUser,
  commonPhoneRequired,
  commonPhoneNumber,
  commonEmail,
  numDash,
  minNoSpaces,
];

const domainRules = {
  [Domain.FI]: [
    selfFinancingAmountFI,
    carRegistrationNumberFI,
    motorcycleRegistrationNumberFI,
    specialRegistrationNumberFI,
    boatRegistrationNumberFI,
    isNotCollateralRegNumberFI,
    vinNumberBoatsFI,
    vinNumberFI,
    isNotCollateralVinFI,
    boatModelYearFI,
    customerIdentificationFI,
    hasValidLicenseFI,
    isNotPartnerIdFI,
    todayOrInFutureFI,
    name,
    simplifiedEmailFI,
    allowRegistryChecksFI,
    personalCodeFI,
    coApplicantPersonalCodeMatchingFI,
    ibanFI,
    mobilePhoneFI,
    postCodeFI,
    phoneFI,
    registrationCodeFi,
    monthYearFI,
    employerNameFI,
  ],
  [Domain.LT]: [
    personalCodeLT,
    ibanLT,
    mobilePhoneLT,
    postCodeLT,
    phoneLT,
    documentStatusOption,
    idDocumentCopyAndConsentAreValidLT,
    spouseIsNotApplicantLT,
  ],
  [Domain.LV]: [
    personalCodeLV,
    mobilePhoneLV,
    postCodeLV,
    ibanLV,
    phoneLV,
    personalCodeAgeLV,
    dateOfBirthLV,
    ageLV,
    charactersLV,
    documentNumberLV,
    idCardLV,
    passportLV,
    addressLV,
    alphaNumLV,
    alphaLV,
  ],
  [Domain.EE]: [
    personalCodeEE,
    ibanEE,
    mobilePhoneEE,
    phoneEE,
    postCodeEE,
  ],
  [Domain.INTERNAL]: [
    name,
    personalCodeEE,
    ibanEE,
    mobilePhoneEE,
    phoneEE,
    postCodeEE,
    personalCodeLV,
    mobilePhoneLV,
    postCodeLV,
    ibanLV,
    phoneLV,
    personalCodeLT,
    ibanLT,
    mobilePhoneLT,
    postCodeLT,
    phoneLT,
    personalCodeFI,
    ibanFI,
    mobilePhoneFI,
    postCodeFI,
    phoneFI,
    personalCodeDoesNotMatchLoggedInUser,
  ],
};

export default function extend (veeValidate) {
  commonRules.forEach(rule => veeValidate.extend(rule.name, rule.validator));

  if (getSelectedDomain()) {
    domainRules[getSelectedDomain()].forEach(rule => veeValidate.extend(rule.name, rule.validator));
  }
}

function amountValidator (value) {
  //Numbers from 0 to 10 000 000 are allowed, customer can insert up to 2 decimal places
  const rule = /^(([1-9]\d{0,5})|([0.])(?![0\d]))(((\d{0,2}){1})?((\.\d{0,2}){1})?(?!,))$/;

  if (!rule.test(value)) {
    return false;
  }

  const asDecimal = Number.parseFloat(value);

  return !Number.isNaN(asDecimal) &&
      Number.isFinite(asDecimal) &&
      asDecimal >= 0 &&
      asDecimal <= 10000000;
}

function stringValidator (value, array) {
  const username = array[0].toLowerCase();
  const fullArr = [
    username,
  ];
  if (username.includes('@')) {
    const name = username.substring(0, username.lastIndexOf('@'));
    const domain = username.substring(username.lastIndexOf('@') + 1);
    fullArr.push(name, domain);

    if (name.includes('.')) {
      fullArr.push(
        name.substring(0, name.lastIndexOf('.')),
        name.substring(name.lastIndexOf('.') + 1),
      );
    }

    if (domain.includes('.')) {
      fullArr.push(domain.substring(0, domain.lastIndexOf('.')));
    }
  } else if (username.includes('.')) {
    fullArr.push(
      username.substring(0, username.lastIndexOf('.')),
      username.substring(username.lastIndexOf('.') + 1),
    );
  }
  const rule = new RegExp(fullArr.join('|')).test(value.toLowerCase());

  return rule && fullArr[0] && true;
}
