import { pmsRequest } from '@/utils/common/request';
import { QuestionnaireType } from '@/types/common/enums';

export interface FeedbackQuestionnaireAnswer {
  partnerId: number,
  partnerUserId: number,
  questionnaireType: QuestionnaireType,
  questionsAndAnswersModel: FeedbackQuestionsAndAnswersModel[]
}

export interface FeedbackQuestionsAndAnswersModel {
  question: string,
  answer: string
}

export interface AmlQuestionnaireModel {
  id?: number,
  partnerId: number,
  partnerUserId: number,
  questionnaireType: QuestionnaireType,
  questionsAndAnswers: AmlQuestionnaireQuestionAndAnswersModel[]
}

export interface AmlQuestionnaireQuestionAndAnswersModel {
  id?: number,
  question: string,
  answers: AmlAnswerQuestionnaireAnswerModel[]
}

export interface AmlAnswerQuestionnaireAnswerModel {
  answer: string,
  id: string,
  selected?: boolean,
  valid?: boolean
}

export default {

  answerAmlQuestionnaire(data: AmlQuestionnaireModel) {
    return pmsRequest({
      url: `/questionnaire/aml`,
      data,
      method: 'post',
    });
  },

  getAmlQuestionnaireQuestions(questionnaireType) {
    return pmsRequest({
      url: `/questionnaire/aml/question/${questionnaireType}`,
      method: 'get',
    });
  },

  answerFeedbackQuestionnaire(data: FeedbackQuestionnaireAnswer) {
    return pmsRequest({
      url: `/questionnaire/feedback`,
      data,
      method: 'post',
    });
  },

  getQuestionnaireAnswers(questionnaireType) {
    return pmsRequest({
      url: `/questionnaire/${questionnaireType}`,
      method: 'get',
    });
  },

  getAmlQuestionnaire () {
    return pmsRequest({
      url: `/questionnaire/aml/question/AML_FORM_LV`,
      method: 'GET',
    });
  },

  editQuestion ({ id, data }) {
    return pmsRequest({
      url: `/questionnaire/aml/question/${id}`,
      method: 'PUT',
      data,
    });
  },

  addQuestion (data) {
    return pmsRequest({
      url: `/questionnaire/aml/question/`,
      method: 'POST',
      data,
    });
  },

  deleteQuestion (id) {
    return pmsRequest({
      url: `/questionnaire/aml/question/${id}`,
      method: 'DELETE',
    });
  },

};
