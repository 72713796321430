import { pmsRequest, request } from './request';
import heartbeat from './heartbeat';
import qs from 'query-string';
import { VueRouter } from 'vue-router/types/router';
import { RouteNames } from '@/types/common/enums';

const DEFAULT_ALIVE_EVENT_NAME = 'alive';
const LOS_HEARTBEAT_EVENT_NAME = 'los_heartbeat';

interface Options {
    heartbeatRequestInterval?: number;
    router: VueRouter;
    aliveRequestInterval?: number;
}

export default function (options: Options) {
  const _logout = () => {
    const actions = () => {
      const userSavedColumns = localStorage.getItem('selectedApplicationColumns') ?? '[]';
      localStorage.clear();
      if (process.env.VUE_APP_IS_DEV !== 'TRUE') {
        localStorage.setItem('selectedApplicationColumns', userSavedColumns);
      }
      heartbeat.killEvent(getAliveEventName(options));
      heartbeat.killEvent(LOS_HEARTBEAT_EVENT_NAME);
      options.router.push({
        name: RouteNames.LOGIN,
      }).catch(() => {});
    };

    return request
      .post('/logout')
      .then(() => actions())
      .catch(err => actions());
  };

  return {
    login (credentials) {
      return request
        .post('/login', qs.stringify(credentials), {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        })
        .then(response => {
          if (response.status === 200) {
            localStorage.setItem('isLoggedIn', 'true');
            this.createAliveHeartbeat();
          }

          return response;
        });
    },

    initiateMobileIdAuthentication ({
      languageCode,
      personalIdentityCode,
      phoneNumber,
    }) {
      return pmsRequest({
        url: '/system-user/mobile-id/initialize',
        data: {
          languageCode,
          personalIdentityCode,
          phoneNumber,
        },
        method: 'post',
      });
    },

    initiateSmartIdAuthentication ({ countryCode, personalIdentityCode }) {
      return pmsRequest({
        url: '/system-user/smart-id/initialize',
        data: {
          countryCode,
          personalIdentityCode,
        },
        method: 'post',
      });
    },

    getDigitalAuthenticationStatus ({ sessionId, isSmartId, isMobileId, isIdCard }) {
      return request
        .post(
          '/login',
          qs.stringify({
            sessionId,
            isSmartId,
            isMobileId,
            isIdCard,
          }),
          {
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
          },
        )
        .then(response => {
          if (response.status === 200) {
            localStorage.setItem('isLoggedIn', 'true');
          }

          return response;
        });
    },

    createAliveHeartbeat () {
      const aliveEvent = heartbeat.getEvent(getAliveEventName(options));
      if (!aliveEvent) {
        heartbeat.createEvent(
          options.aliveRequestInterval || 60,
          {
            name: getAliveEventName(options),
          },
          this.iAmAlive,
        );
      }
    },

    initializeLosHeartbeat () {
      const heartbeatEvent = heartbeat.getEvent(LOS_HEARTBEAT_EVENT_NAME);
      if (!heartbeatEvent) {
        heartbeat.createEvent(
          options.heartbeatRequestInterval || 20,
          {
            name: LOS_HEARTBEAT_EVENT_NAME,
          },
          this.startLosBeatEvent,
        );
      }
    },

    isLoggedIn () {
      return !!localStorage.getItem('isLoggedIn');
    },

    iAmAlive () {
      let retryAttempts = 5;

      const aliveRequest = () =>
        request.get('/alive').catch(err => {
          setTimeout(() => {
            if (retryAttempts) {
              --retryAttempts;
              aliveRequest();
            } else {
              _logout();
            }
          }, 1000);
        });

      aliveRequest();
    },

    startLosBeatEvent () {
      return request.get('/api/session/heartbeat');
    },

    logout () {
      return _logout();
    },
  };
}

function getAliveEventName (options) {
  return options.aliveEventName || DEFAULT_ALIVE_EVENT_NAME;
}
