<template>
  <div>
    <bb-modal
      public-size="m"
      class="align-center"
      :visible="visible"
      static-overlay
      flavor="user"
      @close="confirmationDialogVisibility = true"
    >
      <bb-form
        static-submit
        :submit-text="$t('otpModal.submit')"
        uppercase-buttons
        :loading="loading"
        :disabled="code.length < 6"
        @submit="submit"
      >
        <p class="small color-gray-90 p-b-20 p-t-10">
          {{ mfaTitle }}
        </p>

        <p
          v-if="lblError"
          class="color-red smallest"
        >
          {{ lblError }}
        </p>

        <bb-input
          id="codeInput"
          v-model="code"
          center
          framed
          :placeholder="$t('otpModal.inputPlaceholder')"
          name="code"
          @input="clearError()"
        />
      </bb-form>

      <div class="smallest">
        <bb-button
          id="triggerMfaButton"
          display="link"
          class="p-t-20"
          :disabled="!canResend"
          :color="canResend ? 'mint' : 'gray'"
          :style="canResend ? 'cursor: pointer;' : 'cursor: not-allowed;'"
          @click="triggerMfa"
        >
          {{ $t('otpModal.sendCodeAgain') }}
        </bb-button>

        <p class="p-t-30 color-gray-50">
          {{ $t('otpModal.notYou') }}
        </p>
        <p class="color-gray-50">
          {{ $t('otpModal.contactAdministrator') }}
        </p>
      </div>
    </bb-modal>
    <confirmation-dialog
      :visible="confirmationDialogVisibility"
      :message="$t('otpModal.confirmationDialog')"
      :lbl-no="$t('otpModal.closingDecline')"
      :lbl-yes="$t('otpModal.closingAgree')"
      @yesClick="hideModal"
      @noClick="confirmationDialogVisibility = false"
    />
  </div>
</template>
<script>
  import ConfirmationDialog from './ConfirmationDialog';
  import { BbButton,
           BbForm,
           BbInput,
           BbModal } from '@bigbank/interface-components';
  import { MfaTypeCodes, RouteNames } from '@/types/common/enums';

  export default {
    name: 'otp-modal',
    components: {
      ConfirmationDialog,
      BbButton,
      BbForm,
      BbInput,
      BbModal,
    },
    props: {
      visible: {
        'type': Boolean,
        'default': false,
      },
      maskedContact: {
        type: String,
      },
      mfaTypeCode: {
        'type': String,
        'default': MfaTypeCodes.SMS,
      },
      mfaData: {
        'type': [
          Object,
          Boolean,
        ],
        'default': false,
      },
      resendTimeout: {
        'default': 40000,
        'type': Number,
      },
      lblError: {
        'type': [
          String,
          Boolean,
        ],
        'default': false,
      },
      loading: {
        'type': Boolean,
        'default': false,
      },
    },
    data () {
      return {
        code: '',
        error: null,
        canResend: false,
        confirmationDialogVisibility: false,
      };
    },
    computed: {
      mfaTitle () {
        const titles = {
          SMS: this.$t('otpModal.smsTitle'),
          EMAIL: this.$t('otpModal.emailTitle'),
        };

        return `${titles[this.mfaTypeCode]} ${this.maskedContact}`;
      },
    },
    watch: {
      visible (value) {
        value && this.resetTriggerMfaButton();
      },
    },
    created () {
      this.resetTriggerMfaButton();
    },
    methods: {
      resetTriggerMfaButton () {
        this.canResend = false;
        setTimeout(() => { this.canResend = true; }, this.resendTimeout);
      },
      hideModal () {
        this.confirmationDialogVisibility = false;
        this.code = '';
        this.$emit('close');
      },
      submit () {
        try {
          this.$emit('submit', this.code);
        } catch (err) {
          this.$notification.show(err).error();
          this.$router.push({
            name: RouteNames.ERROR,
          }).catch(() => {});
        }
      },
      triggerMfa () {
        this.resetTriggerMfaButton();
        this.code = '';
        this.clearError();
        this.$emit('triggerMfa');
      },
      clearError () {
        this.$emit('clearError');
      },
    },
  };
</script>

<style lang="scss">
  @import '~@bigbank/interface-components/dist/src/scss/variables';

  .modal {
      font-family: $gotham-medium;
      .field-group {
        padding-top: 0;
      }
      .btn--link {
        font-size: rem(12px);
      }
  }
</style>
