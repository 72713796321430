<template>
  <div
    id="app"
    class="app-container"
    @click="$refs.inactivityModal.initTimeout()"
  >
    <bb-banner
      type="error"
      permanent
      :visible="buildBannerVisible"
    >
      {{ buildBannerText }}
    </bb-banner>
    <bb-functional-sticky v-if="showEnvironmentNotification" position="top">
      <template #keepHeight>
        <bb-ribbon :label="environmentLabel" />
      </template>
    </bb-functional-sticky>
    <router-view class="main-router-view" />
    <inactivity-modal
      ref="inactivityModal"
      :logout-timeout="logoutTimeout"
      :inactivity-warning-timeout="inactivityWarningTimeout"
      @logout="logout"
    />
    <notification-modal />
  </div>
</template>

<script>
  import { request } from './utils/common/request';
  import extendValidation from '@/validation/common/extendValidation';
  import { BbBanner, BbFunctionalSticky, BbRibbon } from '@bigbank/interface-components';
  import NotificationModal from '@/components/common/NotificationModal';
  import InactivityModal from '@/components/common/InactivityModal';
  import { RouteNames } from '@/types/common/enums';
  import i18n from '@/utils/common/i18n';

  export default {
    name: 'app',
    components: {
      BbBanner,
      NotificationModal,
      InactivityModal,
      BbFunctionalSticky,
      BbRibbon,
    },
    data () {
      return {
        logoutTimeout: process.env.LOGOUT_TIMEOUT,
        inactivityWarningTimeout: process.env.INACTIVITY_WARNING_TIMEOUT,
        buildVersion: process.env.VUE_APP_BUILD_VERSION,
        buildBannerVisible: false,
        buildBannerText: this.$t('app.olderVersionNotification'),
      };
    },
    computed: {
      environmentLabel () {
        switch (window.config.APM_ENVIRONMENT) {
        case 'staging':
          return 'Staging environment';
        case 'development':
          return 'Development environment';
        default:
          return 'Local environment';
        }
      },
      showEnvironmentNotification () {
        return window.config.APM_ENVIRONMENT !== 'production';
      },
    },
    $_veeValidate: {
      validator: 'new',
    },
    async created () {
      extendValidation(this.$validator);

      if (this.$auth.isLoggedIn()) {
        await this.$auth.createAliveHeartbeat();
        if (!this.$isInternal()) {
          await this.$auth.initializeLosHeartbeat();
        }
        process.env.NODE_ENV === 'production' && this.$apm.setUserContext({
          username: this.$userData().username,
        });
      }
      if (process.env.NODE_ENV === 'production') {
        this.buildVersionCheckRequest();
        setInterval(() => {
          this.buildVersionCheckRequest();
        }, 30000);
      }
    },
    methods: {
      buildVersionCheckRequest () {
        request.get('/build').then(rs => {
          if (this.$route.name === RouteNames.UNAVAILABLE) {
            this.$router.push({
              name: RouteNames.LOGIN,
            }).catch(() => {});
          }

          this.buildBannerVisible = rs.data !== this.buildVersion;
        }).catch(err => {
          if (err.response.status === 451) {
            this.$router.push({
              name: RouteNames.UNAVAILABLE,
            }).catch(() => {});
          }
        });
      },
      logout () {
        this.$auth.logout().then(() => {
          this.$state.reset();
          this.$refs.inactivityModal.close();
        });
      },
    },
    metaInfo: {
      title: i18n.tc('app.title'),
    },
  };
</script>

<style lang="scss" scoped>
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    min-height: 100vh;
  }

  .app-container {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    flex-flow: column;
  }

  .main-router-view {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-property: opacity;
    transition-duration: 0.2s;
  }

  .fade-enter-active {
    transition-delay: 0.2s;
  }
</style>
