import { VueConstructor } from 'vue';
import { Domain, RoleCode } from '@/types/common/enums';
import { getPrivileges } from '@/utils/common/getPrivileges';

export default function installPrivileges (Vue: VueConstructor) {
  let role: RoleCode = localStorage.user ? JSON.parse(localStorage.user).roleCode : RoleCode.PUBLIC;

  if (localStorage.role && window.config.DOMAIN === Domain.INTERNAL) {
    role = localStorage.role;
  }

  if (Vue) {
    Vue.prototype.$rolePrivileges = {
      _vm: new Vue({
        data: {
          role,
          privileges: getPrivileges(),
          country: window.config.DOMAIN || Domain.UNDEFINED,
        },
        watch: {
          role () {
            this.rolePrivileges();
          },
          country () {
            this.rolePrivileges();
          },
        },
        created () {
          this.rolePrivileges();
        },
        methods: {
          rolePrivileges () {
            const role = this.role;
            const country = this.country;
            Vue.directive('can', {
              inserted (el, binding) {
                const rolePrivilege = binding.value[0];
                const elementPrivilege = binding.value[1];
                const canSee = getPrivileges()[country][role] ? getPrivileges()[country][role][rolePrivilege] ? getPrivileges()[country][role][rolePrivilege].includes(elementPrivilege) : false : false;
                !canSee && el.remove();
              },
            });

            Vue.prototype.$can = (binding) => {
              const rolePrivilege = binding[0];
              const elementPrivilege = binding[1];
              const canSee = getPrivileges()[country][role] ? getPrivileges()[country][role][rolePrivilege] ? getPrivileges()[country][role][rolePrivilege].includes(elementPrivilege) : false : false;

              return canSee;
            };
          },
        },
      }),
      changeRole (role: RoleCode) {
        this._vm.$data.role = role;
      },
      setCountry (country: Domain) {
        this._vm.$data.country = country;
      },
    };
  } else {
    const isVueSet = !Vue ? 'Vue not found!' : undefined;
    const dependencies = [
      isVueSet,
    ];
    dependencies.map(dependency => {
      if (dependency) {
        throw new Error(dependency);
      }

      return null;
    });
  }
}
