import { Domain } from '@/types/common/enums';

export const getDefaultLoanPeriod = () => {
  if (window.config.DOMAIN === Domain.FI) {
    return 60;
  }

  if (window.config.DOMAIN === Domain.LV) {
    return 84;
  }

  return 12;
};
