import { DecisionRule } from '@/types/common/enums';
import i18n from '@/utils/common/i18n';

export const languageCodeOptions = [
  {
    text: 'EE',
    value: 'EE',
  },
  {
    text: 'RU',
    value: 'RU',
  },
];

export const eeCountryCode = '+372';

export const eePhoneSupportedCountries = [
  {
    alpha2: 'EE',
    callingCode: '+372',
    name: i18n.tc('Estonia'),
  },
  {
    alpha2: 'FI',
    callingCode: '+358',
    name: i18n.tc('Finland'),
  },
];

export const addressAdministrativeAreaKeys = [
  'administrativeAreaLevel3',
  'administrativeAreaLevel2',
  'administrativeAreaLevel1',
];

export const shownDecisionRules = [
  DecisionRule.NO_LIVING_PERMIT,
  DecisionRule.ADDRESS_ABROAD,
  DecisionRule.CUSTOMER_MAX_AGE,
  DecisionRule.CUSTOMER_MIN_AGE,
  DecisionRule.CUSTOMER_DTI_OVER_LIMIT,
  DecisionRule.CUSTOMER_RESERVE_NEGATIVE,
  DecisionRule.CUSTOMER_RESERVE_NEGATIVE,
  DecisionRule.CUSTOMER_MAX_LOAN_SUM_BELOW_LIMIT,
  DecisionRule.EXISTING_LOAN_FIRST_PAYMENT_NOT_DONE,
  DecisionRule.INCOME_TYPE_SOCIAL_BENEFIT,
  DecisionRule.NET_INCOME_UNDER_MIN_INDIVIDUAL,
  DecisionRule.CUSTOMER_UNEMPLOYED,
];
