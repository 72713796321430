import axios, { AxiosRequestConfig } from 'axios';
import { get } from 'lodash-es';
import { Domain } from '@/types/common/enums';
import { getSelectedDomain } from '@/utils/common/getSelectedDomain';

const PMS_PROXY_PREFIX = process.env.PMS_PROXY_PREFIX || 'pms';
const BONUS_PROXY_PREFIX = process.env.BONUS_PROXY_PREFIX || 'bonus';

export const request = axios.create({
  baseURL: window.location.origin,
});

export const domainRequestWithData = (config) =>
  domainRequest({
    ...config,
  }).then(response => {
    if (window.config.DOMAIN === Domain.LT && response.status === 451) {
      window.location.href = `${window.location.origin}/unavailable`;

      return;
    }

    return response.data;
  });

export const domainRequest = (config: AxiosRequestConfig) => {
  const baseURL = getSelectedDomain() === Domain.LT
    ? window.location.origin
    : `${window.location.origin}/${getSelectedDomain().toLowerCase()}/`;

  return request({
    baseURL,
    ...config,
  });
};

export const pmsRequest = (config: AxiosRequestConfig) => request({
  baseURL: `${window.location.origin}/${PMS_PROXY_PREFIX}`,
  ...config,
});

export const bonusRequest = (config: AxiosRequestConfig) => request({
  baseURL: `${window.location.origin }/${ BONUS_PROXY_PREFIX }`,
  ...config,
});

export function containsCode (response, code) {
  return !!get(response, 'data.errors', []).some(error => error.code === code);
}
