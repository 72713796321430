import { UserData } from '@/types/types';
import { Domain, RoleCode } from '@/types/common/enums';
import dayjs from 'dayjs';

export function getShouldShowQuestionnaireModal() {

  const domain = window.config.DOMAIN;
  const userData: UserData = localStorage.user ? JSON.parse(localStorage.user) : {};

  const shouldShow = !userData.feedbackFormSubmittedDate &&
      window.config.QUESTIONNAIRE_ENABLED === 'true' &&
      userData.roleCode !== RoleCode.BIGBANK_SUPER_ADMIN &&
      domain !== Domain.INTERNAL &&
      dayjs().diff(userData.createdDtime, 'month') >= 1;

  if (domain === Domain.FI || domain === Domain.LV || Domain.EE) {
    return shouldShow && userData.amlTrainingDate;
  }

  return shouldShow;
}
