import { pmsRequest } from '../utils/common/request';
import queryString from 'query-string';

export default {

  trigger (parameters) {
    const uri = parameters ? `?${ queryString.stringify(parameters)}` : '';

    return pmsRequest({
      url: `/system-user/mfa/trigger${uri}`,
      method: 'post',
    });
  },
  resend (id) {
    return this.trigger({
      subjectType: 'RESEND',
      subjectId: id,
    });
  },

};

