import { IOptionsFromEnum } from '@/types/common/interfaces';
import {
  AssetCondition,
  CollateralMainType,
  CollateralSubType,
  CommonTaskDefinitionKeys,
  GoodsCategory,
  InterestType,
  PersonRole,
  PersonType, SearchType,
  YesNo,
} from '@/types/common/enums';
import { LvTaskDefinitionKeys } from '@/types/lv/enums';
import { VehicleType } from '@/types/fi/enums';
import { getDefaultLoanPeriod } from '@/utils/common/getDefaultLoanPeriod';
import { EeTaskDefinitionKeys } from '@/types/ee/enums';
import { LtTaskDefinitionKeys } from '@/types/lt/enums';
import { getDefaultLoanAmount } from '@/utils/common/getDefaultLoanAmount';

export const dailyAprcLimit = 0.00068;

export const approvedSteps = [
  LvTaskDefinitionKeys.REVIEW_APPROVED,
  LvTaskDefinitionKeys.REVIEW_APPROVED_FINAL,
  LvTaskDefinitionKeys.REVIEW_APPROVED_UPSALE,
  LvTaskDefinitionKeys.REVIEW_APPROVED_UPSALE_FINAL,
  LvTaskDefinitionKeys.PARTNER_SUBMIT_ADDITIONAL_DATA,
  LvTaskDefinitionKeys.VIEW_CONTRACT_CONDITIONS,
  LvTaskDefinitionKeys.VERIFY_THAT_CONTRACT_IS_SIGNED,
  LvTaskDefinitionKeys.CHOOSE_SIGNING_METHOD,
  LtTaskDefinitionKeys.REVIEW_APPROVED,
  EeTaskDefinitionKeys.POSITIVE_DECISION,
  EeTaskDefinitionKeys.VIEW_OFFER_BAS_NEEDED,
  EeTaskDefinitionKeys.VIEW_OFFER,
  EeTaskDefinitionKeys.VIEW_OFFER_MANUAL_CHECK_NEEDED,
  LtTaskDefinitionKeys.REVIEW_APPROVED,
  LtTaskDefinitionKeys.REVIEW_APPROVED_UPSALE,
  LtTaskDefinitionKeys.REVIEW_APPROVED_FINAL,
  LtTaskDefinitionKeys.REVIEW_APPROVED_UPSALE_FINAL,
  LtTaskDefinitionKeys.VIEW_AND_SIGN_CONTRACT,
];

export const calculatorEnabledSteps = [
  LvTaskDefinitionKeys.REVIEW_APPROVED,
  LvTaskDefinitionKeys.REVIEW_APPROVED_FINAL,
  LvTaskDefinitionKeys.REVIEW_APPROVED_UPSALE,
  LvTaskDefinitionKeys.REVIEW_APPROVED_UPSALE_FINAL,
  LvTaskDefinitionKeys.SHOW_LOAN_AND_CONTACT_DATA_INCOMES_AND_LIABILITIES_PAGE,
  EeTaskDefinitionKeys.VIEW_OFFER_MANUAL_CHECK_NEEDED,
  EeTaskDefinitionKeys.VIEW_OFFER_BAS_NEEDED,
  EeTaskDefinitionKeys.VIEW_OFFER,
  EeTaskDefinitionKeys.POSITIVE_DECISION,
  EeTaskDefinitionKeys.CONFIRM_CUSTOMERS_NAME,
  EeTaskDefinitionKeys.SUBMIT_INCOME_AND_LIABILITIES,
  CommonTaskDefinitionKeys.NEW_PARTNER_APPLICATION,
  CommonTaskDefinitionKeys.NEW_SHORT_APPLICATION,
  CommonTaskDefinitionKeys.NEW_REMOTE_APPLICATION,
  CommonTaskDefinitionKeys.NEW_CAR_LEASING_APPLICATION,
  LtTaskDefinitionKeys.SUBMIT_BANK_DETAILS,
  LtTaskDefinitionKeys.SHOW_SPOUSE_INCOMES_AND_LIABILITIES_FIELDS,
  LtTaskDefinitionKeys.SHOW_SPOUSE_CONTACT_OR_FULL_DATA_PAGE,
  LtTaskDefinitionKeys.READ_CONDITIONAL_REFINANCE_POSITIVE_OFFER,
  LtTaskDefinitionKeys.READ_CONDITIONAL_POSITIVE_OFFER,
  LtTaskDefinitionKeys.REVIEW_APPROVED,
  LtTaskDefinitionKeys.REVIEW_APPROVED_FINAL,
  LtTaskDefinitionKeys.REVIEW_APPROVED_UPSALE,
  LtTaskDefinitionKeys.REVIEW_APPROVED_UPSALE_FINAL,
  LtTaskDefinitionKeys.VIEW_AND_SIGN_CONTRACT,
];

export const annulForbiddenSteps = [
  LtTaskDefinitionKeys.SIGN_LOAN_TASK,
  LvTaskDefinitionKeys.SIGN_LOAN_TASK,
];

export const analysingSteps = [
  LvTaskDefinitionKeys.READ_UNDECIDED_HOUSING_LOAN_DECISION,
  LvTaskDefinitionKeys.BANK_ACCOUNT_STATEMENT_UPLOAD_SKIPPED_THANK_YOU_PAGE,
  LvTaskDefinitionKeys.BANK_ACCOUNT_STATEMENT_PROCESSING_TAKES_TIME_THANK_YOU_PAGE,
  LvTaskDefinitionKeys.BANK_ACCOUNT_STATEMENT_UPLOAD_RETRIES_EXCEEDED_THANK_YOU_PAGE,
  LvTaskDefinitionKeys.ANALYZE_BANK_ACCOUNT_STATEMENT_DOCUMENTS_THANK_YOU_PAGE,
  LvTaskDefinitionKeys.BANK_ACCOUNT_STATEMENTS_BEING_PROCESSED_THANK_YOU_PAGE,
  LvTaskDefinitionKeys.ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE,
  LvTaskDefinitionKeys.ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE_BEFORE_IS_REFINANCING_ENABLED_CHECK,
  LvTaskDefinitionKeys.ANALYSING,
  LvTaskDefinitionKeys.SHORT_APPLICATION_SUBMITTED_THANK_YOU_PAGE,
  EeTaskDefinitionKeys.ANALYSING_AFTER_SUBMIT_THANK_YOU_PAGE,
  EeTaskDefinitionKeys.VIEW_ANALYSING_THANK_YOU_PAGE,
  EeTaskDefinitionKeys.VIEW_APPROVED_ADDITIONAL_CHECKS_THANK_YOU_PAGE,
  EeTaskDefinitionKeys.ANALYSING_THANK_YOU_PAGE,
  EeTaskDefinitionKeys.VALIDATE_UPLOADED_DOCUMENTS,
  LtTaskDefinitionKeys.ANALYSING_DATA,
  LtTaskDefinitionKeys.SPOUSE_FINANCE_DATA_SUBMITTED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.ANALYSING_APPLICATION_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.UNEXPECTED_DECISION_ON_CONTRACT_GENERATION_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.UNEXPECTED_DECISION_ON_PARTNER_CONTRACT_GENERATION_THANK_YOU_PAGE,
];

export const rejectedSteps = [
  LvTaskDefinitionKeys.READ_NEGATIVE_DECISION,
  LvTaskDefinitionKeys.SHOW_NEGATIVE_THANK_YOU_PAGE,
  LvTaskDefinitionKeys.SHOW_CONTRACT_SIGNING_NEGATIVE_PAGE,
  EeTaskDefinitionKeys.VIEW_NEGATIVE_DECISION_THANK_YOU_PAGE,
  EeTaskDefinitionKeys.VIEW_NEGATIVE_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.READ_NEGATIVE_DECISION,
  LtTaskDefinitionKeys.SHOW_NEGATIVE_THANK_YOU_PAGE,
  LtTaskDefinitionKeys.SHOW_CONTRACT_SIGNING_NEGATIVE_PAGE,
];

export const contractSignedSteps = [
  LvTaskDefinitionKeys.CONTRACT_IS_SIGNED_THANK_YOU_PAGE,
  LvTaskDefinitionKeys.CONTRACT_SIGNED_THANK_YOU_PAGE,
  LvTaskDefinitionKeys.PARTNER_CONTRACT_IS_SIGNED_THANK_YOU_PAGE,
];

export const yesNoOptions: IOptionsFromEnum<YesNo> = {
  yes: YesNo.YES,
  no: YesNo.NO,
};

export const defaultSearch = () => {
  return {
    // filter area single-value searches
    [SearchType.PARTNER_TYPE_CODE]: null,
    [SearchType.USER_STATUS]: null,
    [SearchType.BONUS_APPLICATION_STATUS]: null,
    [SearchType.CHANNEL_TYPE]: null,
    [SearchType.FIRST_NAME]: null,
    [SearchType.LAST_NAME]: null,
    [SearchType.USERNAME]: null,
    [SearchType.ROLE_CODE]: null,
    [SearchType.START_DATE]: null,
    [SearchType.END_DATE]: null,
    [SearchType.CONTRACT_SIGNING_OFFICE]: null,
    [SearchType.CONSENT_SIGNING_OFFICE]: null,
    [SearchType.CONSENT_SIGNING_USER]: null,
    // toolbar search
    [SearchType.NAME]: null,
    [SearchType.PERSONAL_CODE]: null,
    [SearchType.APPLICATION_NUMBER]: null,
    [SearchType.CONTRACT_NUMBER]: null,
    // data object containing search
    [SearchType.PARTNER]: null,
    [SearchType.CHANNEL]: null,
    [SearchType.USER]: null,
  };
};

export const defaultConfiguration = {
  customerMinAmount: null,
  customerMaxAmount: null,
  customerMaxMonthlyPaymentFull: null,
  offeredMonthlyPaymentFull: null,
  offeredAmount: null,
  offeredPeriod: null,
  customerMinPeriod: null,
  customerMaxPeriod: null,
  basePeriod: null,
  interestRate: false,
  interestRateMin: null,
  interestRateMax: null,
  loanType: null,
  minGoodsPrice: null,
  maxGoodsPrice: null,
  selfFinancingMinPercent: null,
  administrationFeeType: null,
  administrationFee: null,
  productType: null,
};

export const defaultOffer = {
  interestRate: null,
  conclusionFee: null,
  offeredAmount: null,
  offeredMonthlyPaymentFull: null,
  offeredProductName: null,
  customerMaxAmount: null,
  customerMinAmount: null,
  customerMinPeriod: null,
  customerMaxPeriod: null,
  offeredPeriod: null,
  aprc: null,
  interestFromLoanSum: null,
  conclusionFeePercent: null,
};

export const defaultPersonData = () => {
  return {
    type: PersonType.PHYSICAL,
    personalIdentityCode: null,
    communicationMeans: {
      email: null,
      mobileNumber: null,
      mobileNumberCountryCode: null,
      contactAddress: {
        administrativeAreaLevel1: null,
        administrativeAreaLevel2: null,
        administrativeAreaLevel3: null,
        countryCode: null,
        flatNumber: null,
        houseNumber: null,
        postalCode: null,
        street: null,
      },
    },
    phonePassword: null,
    dateOfBirth: null,
    consents: {
      agreeToPersonalDataProcessing: null,
      allowRegistryCheck: null,
      marketingConsent: false,
    },
    citizenCountryCodes: null,
    declaredData: {
      firstName: null,
      surname: null,
      dependents: null,
      maritalStatus: null,
      isPep: null,
    },
    idDocuments: [
      {
        type: null,
        dateOfExpiry: null,
        number: null,
        issuingAuthority: null,
      },
    ],
    identification: {
      dateTime: null,
      method: null,
    },
    incomes: [],
    liabilities: [],
    employments: [
      {
        position: null,
        activityStartDate: null,
        fieldOfActivity: null,
        companyName: null,
        companyActivityCode: null,
        companyRegistrationCode: null,
      },
    ],
    documents: [
      {
        creationDateTime: null,
        fileServiceId: null,
        id: null,
        resolution: null,
        type: null,
      },
    ],
  };
};

export const CommonApplicationState = () => ({
  type: PersonType.PHYSICAL,
  currency: 'EUR',
  countrySpecificData: {
    lv: {
      expectedContractSignatureType: null,
      applicantDeclaredIncomeType: null,
      basBatches: [],
      contractDownloadedDateTime: null,
    },
    fi: {
      [PersonRole.APPLICANT]: {
        hasDriversLicence: null,
        militaryServiceStatus: null,
      },
      [PersonRole.COBORROWER]: {
        militaryServiceStatus: null,
      },
    },
    lt: {
      forFamilyUse: false,
      contractNeedsManualReview: false,
      [PersonRole.APPLICANT]: {
        consentDateTime: null,
        consentGiven: null,
        consentId: null,
        consentMethod: null,
        consentMethodCopiedFrom: null,
        consentSigningOffice: null,
        consentSigningUser: null,
        incomeMayDecrease: null,
        probableDecreasedIncome: null,
        requiredIncomeDocuments: [],
        requiredLeasingDocuments: [],
        requiredRefinancingDocuments: [],
        requiredEmploymentDocuments: [],
      },
      [PersonRole.SPOUSE]: {
        consentDateTime: null,
        consentGiven: null,
        consentId: null,
        consentMethod: null,
        consentMethodCopiedFrom: null,
        consentSigningOffice: null,
        consentSigningUser: null,
        incomeMayDecrease: null,
        probableDecreasedIncome: null,
        requiredIncomeDocuments: [],
        requiredLeasingDocuments: [],
        requiredRefinancingDocuments: [],
        requiredEmploymentDocuments: [],
      },
      [PersonRole.COBORROWER]: {
        consentDateTime: null,
        consentGiven: null,
        consentId: null,
        consentMethod: null,
        consentMethodCopiedFrom: null,
        consentSigningOffice: null,
        consentSigningUser: null,
        incomeMayDecrease: null,
        probableDecreasedIncome: null,
      },
    },
    ee: {
      applicantIdDocumentNumber: null,
    },
  },
  involveCoBorrower: false,
  countrySpecificProcessData: {
    lv: {
      offerChangeEnabled: false,
    },
    ee: {
      prefillApplication: false,
    },
  },
  creditConditions: {
    campaignCode: null,
    commission: null,
    loanAmount: getDefaultLoanAmount(),
    loanPeriod: getDefaultLoanPeriod(),
    loanPurpose: null,
    loanPurposeText: null,
    minReserve: null,
    monthlyPaymentDay: null,
    productName: null,
    segmentName: null,
    selfFinancingAmount: null,
    selfFinancingPercent: null,
    interestType: InterestType.FIX,
    assetCondition: AssetCondition.NEW,
  },
  goodsAndServices: [
    {
      name: null,
      price: null,
      leasing: {
        sellerId: null,
      },
    },
  ],
  offerAccepted: false,
  productSpecific: {
    renovation: {
      address: {
        administrativeAreaLevel1: null,
        administrativeAreaLevel2: null,
        administrativeAreaLevel3: null,
        countryCode: null,
        flatNumber: null,
        houseNumber: null,
        postalCode: null,
        street: null,
      },
    },
    carHp: {
      tradeInAsset: null,
      plannedActivationDate: new Date(),
    },
  },
  offer: {},
  contract: {
    expectedSigningDate: null,
    signing: {
      preferredSigningMethod: null,
    },
  },
  loanAmountWithConclusionFee: null,
  flatRelatedPersons: {
    [PersonRole.APPLICANT]: {
      role: PersonRole.APPLICANT,
      ...defaultPersonData(),
    },
    [PersonRole.SPOUSE]: {
      role: PersonRole.SPOUSE,
      ...defaultPersonData(),
    },
    [PersonRole.COBORROWER]: {
      role: PersonRole.COBORROWER,
      ...defaultPersonData(),
    },
  },
  flatCollaterals: {
    [VehicleType.COLLATERAL]: {
      subType: CollateralSubType.LEASED_VEHICLES,
      mainType: CollateralMainType.OTHER_PHYSICAL_COLLATERALS,
      asset: {
        brand: null,
        model: null,
        vinCode: null,
        name: null,
        registrationNumber: null,
        kilometrage: null,
        hasNoRegistrationNumber: false,
        firstUseDate: null,
        marketValue: null,
        lastInspectionDate: null,
        inspectionValidUntil: null,
        firstRegistrationDate: null,
        boatMileage: null,
        boatHullModelYear: null,
        boatEngines: [],
        trafiResponseModel: null,
      },
    },
    [VehicleType.TRADE_IN]: {
      asset: {
        brand: null,
        model: null,
        vinCode: null,
        name: null,
        registrationNumber: null,
        kilometrage: null,
        hasNoRegistrationNumber: false,
        firstUseDate: null,
        marketValue: 0,
        residualValue: 0,
        tradeInMonthlyPayment: 0,
        lastInspectionDate: null,
        inspectionValidUntil: null,
        firstRegistrationDate: null,
        boatMileage: null,
        boatHullModelYear: null,
        boatEngines: [],
        trafiResponseModel: null,
      },
    },
  },
  flatGoodsAndServices: {
    [GoodsCategory.MOTO]: {
      category: GoodsCategory.MOTO,
      price: 0,
      priceIncludesVAT: true,
      name: null,
    },
    [GoodsCategory.SERVICES]: {
      category: GoodsCategory.SERVICES,
      name: 'Delivery',
      price: 0,
      priceIncludesVAT: true,
      deliveryDate: new Date(),
    },
  },
  paypostSigningTaskId: null,
  sentByEmail: false,
  paypostSigningActive: null,
});
