export enum EeTaskDefinitionKeys {
    RETRY_UPDATE_APPLICATION_DATA_FROM_POPULATION_REGISTRY = 'retryUpdateApplicantDataFromPopulationRegistry',
    CONFIRM_CUSTOMERS_NAME = 'confirmCustomersName',
    SUBMIT_INCOME_AND_LIABILITIES = 'submitIncomeAndLiabilities',
    VIEW_OFFER_MANUAL_CHECK_NEEDED = 'viewOfferManualCheckNeeded',
    VIEW_OFFER = 'viewOffer',
    POSITIVE_DECISION = 'positiveDecision',
    VIEW_OFFER_BAS_NEEDED = 'viewOfferBasNeeded',
    SUBMIT_CONSENTS_AND_ADDITIONAL_DATA = 'submitConsentsAndAdditionalData',
    IDENTIFY_CUSTOMER = 'identifyCustomer',
    VIEW_AND_SIGN_HP_CONTRACT = 'viewAndSignHpContract',
    VIEW_PARTNER_UNDECIDED_THANK_YOU_PAGE = 'viewPartnerUndecidedThankYouPage',
    VIEW_APPROVED_ADDITIONAL_CHECKS_THANK_YOU_PAGE = 'viewApprovedAdditionalChecksThankYou',
    VIEW_NEGATIVE_DECISION_THANK_YOU_PAGE = 'viewNegativeDecisionThankYouPage',
    VIEW_NEGATIVE_THANK_YOU_PAGE = 'viewNegativeThankYouPage',
    VIEW_HP_THANK_YOU_PAGE = 'viewHpThankYouPage',
    VIEW_UPLOAD_DOCUMENTS_THANK_YOU_PAGE = 'viewUploadDocumentsThankYouPage',
    ANNUL_THANK_YOU_PAGE = 'annulThankYouPage',
    ANALYSING_THANK_YOU_PAGE = 'analysingThankYouPage',
    VIEW_ANALYSING_THANK_YOU_PAGE = 'viewAnalysingThankYouPage',
    VIEW_TAX_REGISTRY_CONSENT_CHOSEN_THANK_YOU_PAGE = 'viewTaxRegistryConsentChosenThankYouPage',
    SUBMIT_TAX_REGISTRY_CONSENT = 'submitTaxRegistryConsent',
    ANALYSING_AFTER_SUBMIT_THANK_YOU_PAGE = 'analysingAfterSubmitThankYouPage',
    TIMEOUT_THANK_YOU_PAGE = 'timeoutThankYouPage',
    UPLOAD_DOCUMENTS = 'uploadDocuments',
    ACTIVATE_CONTRACT = 'activateContract',
    INFORM_PARTNER_ABOUT_NEED_OF_MANUAL_ANALYSIS = 'informPartnerAboutNeedOfManualAnalysis',
    INFORM_PARTNER_ABOUT_NEED_OF_MANUAL_ANALYSIS_FINAL = 'informPartnerAboutNeedOfManualAnalysisFinal',
    SUBMIT_ADDITIONAL_DATA = 'submitAdditionalData',
    VIEW_DOCUMENTS_UPLOADED_THANK_YOU_PAGE = 'viewDocumentsUploadedThankYouPage',
    VALIDATE_UPLOADED_DOCUMENTS = 'validateUploadedDocuments',
    ANALYSE = 'analyse',
    PROVIDE_BAS_VIA_OPEN_BANKING = 'provideBasViaOpenBanking'
}
