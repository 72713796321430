export enum LvTaskDefinitionKeys {
    READ_UNDECIDED_DECISION = 'ReadUndecidedDecision',
    READ_UNDECIDED_HOUSING_LOAN_DECISION = 'ReadUndecidedHousingLoanDecision',
    ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE_BEFORE_PROCESSING_UNDERWRITER_MANUAL_RULES = 'AdditionalDataSubmittedThankYouPage_BeforeProcessingUnderwriterManualRules',
    ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE_BEFORE_IS_REFINANCING_ENABLED_CHECK = 'AdditionalDataSubmittedThankYouPage_BeforeIsRefinancingEnabledCheck',
    ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE_BEFORE_REFINANCING_SUBFLOW = 'AdditionalDataSubmittedThankYouPage_BeforeRefinancingSubflow',
    ADDITIONAL_DATA_SUBMITTED_THANK_YOU_PAGE = 'AdditionalDataSubmittedThankYouPage',
    DUPLICATE_APPLICATION_THANK_YOU_PAGE = 'DuplicateApplicationThankYouPage',
    REVIEW_APPROVED = 'ReviewApproved',
    REVIEW_APPROVED_UPSALE = 'ReviewApprovedUpsale',
    REVIEW_APPROVED_FINAL = 'ReviewApprovedFinal',
    REVIEW_APPROVED_UPSALE_FINAL = 'ReviewApprovedUpsaleFinal',
    BANK_ACCOUNT_STATEMENTS_BEING_PROCESSED_THANK_YOU_PAGE = 'BankAccountStatementsBeingProcessedThankYouPage',
    BANK_ACCOUNT_STATEMENT_PROCESSING_TAKES_TIME_THANK_YOU_PAGE = 'BankAccountStatementProcessingTakesTimeThankYouPage',
    ANALYZE_BANK_ACCOUNT_STATEMENT_DOCUMENTS = 'AnalyzeBankAccountStatementDocuments',
    ANALYZE_BANK_ACCOUNT_STATEMENT_DOCUMENTS_THANK_YOU_PAGE = 'AnalyzeBankAccountStatementDocumentsThankYouPage',
    POST_OFFICE_CHOSEN_FOR_CONTRACT_SIGNING_THANK_YOU_PAGE = 'PostOfficeChosenForContractSigningThankYouPage',
    VIEW_AND_SIGN_CONTRACT_CONTENT_LOADER = 'ViewAndSignContractContentLoader',
    VIEW_CONTRACT_CONDITIONS = 'ViewContractConditions',
    PARTNER_CONTRACT_IS_SIGNED_THANK_YOU_PAGE = 'PartnerContractIsSignedThankYouPage',
    CHECK_SIGNED_DOCUMENTS = 'CheckSignedDocuments',
    READ_NEGATIVE_DECISION = 'ReadNegativeDecision',
    READ_NEGATIVE_DECISION_INITIAL = 'ReadNegativeDecision_Initial',
    READ_NEGATIVE_DECISION_REFINANCING = 'ReadNegativeDecision_Refinancing',
    READ_NEGATIVE_DECISION_FINAL = 'ReadNegativeDecisionFinal',
    SHOW_CONTRACT_SIGNING_NEGATIVE_PAGE = 'ShowContractSigningNegativePage',
    SHOW_NEGATIVE_THANK_YOU_PAGE = 'showNegativeThankYouPage',
    CONTRACT_SIGNED_IN_POST_OFFICE_END_EVENT = 'ContractSignedInPostOfficeEndEvent',
    SUBMIT_BACKGROUND_INFO = 'SubmitBackgroundInfo',
    REVIEW_APPROVED_ADDITIONAL_CHECK = 'ReviewApprovedAdditionalCheck',
    SUBMIT_BANK_DETAILS_AND_IDENTIFICATION_DOCUMENT_INFO = 'SubmitBankDetailsAndIdentificationDocumentInfo',
    SUBMIT_ADDRESS = 'SubmitAddress',
    PARTNER_SUBMIT_ADDITIONAL_DATA = 'PartnerSubmitAdditionalData',
    SHOW_LOAN_AND_CONTACT_DATA_INCOMES_AND_LIABILITIES_PAGE = 'ShowLoanAndContactDataIncomesAndLiabilitiesPage',
    SUBMIT_SIGNING_DETAILS = 'SubmitSigningDetails',
    SHOULD_SEND_BANK_ACCOUNT_STATEMENT_PROCESSED_EMAIL_EXCLUSIVE_GATEWAY = 'ShouldSendBankAccountStatementProcessedEmailExclusiveGateway',
    UPLOAD_BANK_ACCOUNT_STATEMENT_DOCUMENTS = 'UploadBankAccountStatementDocuments',
    BANK_ACCOUNT_STATEMENT_UPLOAD_SKIPPED_THANK_YOU_PAGE = 'BankAccountStatementUploadSkippedThankYouPage',
    BANK_ACCOUNT_STATEMENT_UPLOAD_RETRIES_EXCEEDED_THANK_YOU_PAGE = 'BankAccountStatementUploadRetriesExceededThankYouPage',
    GENERATE_CONTRACT = 'GenerateContract',
    VERIFY_THAT_CONTRACT_IS_SIGNED = 'VerifyThatContractIsSigned',
    CONTRACT_IS_SIGNED_THANK_YOU_PAGE = 'ContractIsSignedThankYouPage',
    CONTRACT_SIGNED_THANK_YOU_PAGE = 'ContractSignedThankYouPage',
    START_PARTNER_SIGNING = 'StartPartnerSigning',
    ANNUL_THANK_YOU_PAGE = 'AnnulThankYouPage',
    ANALYSING = 'Analysing',
    SHORT_APPLICATION_SUBMITTED_THANK_YOU_PAGE = 'ShortApplicationSubmittedThankYouPage',
    CONTINUE_BBO_PRINTOUT = 'ContinueBboPrintout',
    SIGN_PROMISE_TO_PAY_TASK = 'signPromiseToPayTask',
    SIGN_LOAN_TASK = 'signLoanTask',
    SUBMIT_EMPLOYER_INFO = 'SubmitEmployerInfo',
    CHOOSE_SIGNING_METHOD = 'ChooseSigningMethod',
    PROVIDE_BAS_VIA_OPEN_BANKING = 'ProvideBasViaOpenBanking',
    VALIDATE_UPLOADED_STATEMENTS_AFTER_VALIDATION_RESULT = 'ValidateUploadedStatementsAfterValidationResult',
    HANDLE_INVALID_SIGNED_DOCUMENTS = 'HandleInvalidSignedDocuments',
    INITIATE_PAYOUT = 'InitiatePayout',
}
