import i18n from '@/utils/common/i18n';
import { ApplicationKeys, ApplicationType, Domain } from '@/types/common/enums';
import { getSelectedDomain } from '@/utils/common/getSelectedDomain';
import { sortDateArray, sortNumberArray, sortStatusArray } from '@/utils/common/sortArray';

interface GetApplicationColumns {
  forCsvExport?: boolean;
  type?: ApplicationType;
  showUnfilteredColumns?: boolean;
}

export const getApplicationColumns = ({ forCsvExport, showUnfilteredColumns, type }: GetApplicationColumns = {
  forCsvExport: false,
  showUnfilteredColumns: false,
}) => {

  const isInternal = window.config.DOMAIN === Domain.INTERNAL;

  const columns = {
    [Domain.EE]: [
      {
        key: ApplicationKeys.EDIT,
        label: i18n.tc('applicationColumns.editApplication'),
      },
      {
        key: ApplicationKeys.CHANNEL,
        label: i18n.tc('applicationColumns.channel'),
      },
      {
        key: ApplicationKeys.USERNAME,
        label: i18n.tc('applicationColumns.username'),
      },
      {
        key: ApplicationKeys.CREATION_DATETIME,
        label: i18n.tc('applicationColumns.creationDatetime'),
        sorter: (data, key) => sortDateArray(data, key),
      },
      {
        key: ApplicationKeys.APPLICATION_NUMBER,
        label: i18n.tc('applicationColumns.applicationNumber'),
      },
      {
        key: ApplicationKeys.STATUS,
        label: i18n.tc('applicationColumns.status'),
        sorter: (data) => sortStatusArray(data),
      },
      {
        key: ApplicationKeys.FIRST_NAME,
        label: i18n.tc('applicationColumns.firstName'),
      },
      {
        key: ApplicationKeys.SURNAME,
        label: i18n.tc('applicationColumns.surname'),
      },
      {
        key: ApplicationKeys.PERSONAL_IDENTITY_CODE,
        label: i18n.tc('applicationColumns.personalIdentityCode'),
      },
      {
        key: ApplicationKeys.PRODUCT_NAME,
        label: i18n.tc('applicationColumns.productName'),
      },
      {
        key: ApplicationKeys.LOAN_AMOUNT,
        label: i18n.tc('applicationColumns.loanAmount'),
        sorter: (data, key) => sortNumberArray(data, key),
      },
      {
        key: ApplicationKeys.SELF_FINANCING_AMOUNT,
        label: i18n.tc('applicationColumns.selfFinancingAmount'),
        sorter: (data, key) => sortNumberArray(data, key),
      },
      {
        key: ApplicationKeys.GOODS_PRICE,
        label: i18n.tc('applicationColumns.goodsPrice'),
        sorter: (data, key) => sortNumberArray(data, key),
      },
      {
        key: ApplicationKeys.GOODS_NAME,
        label: i18n.tc('applicationColumns.goodsName'),
      },
      {
        key: ApplicationKeys.CONTRACT_SIGNING_DATETIME,
        label: i18n.tc('applicationColumns.contractSigningDatetime'),
        sorter: (data, key) => sortDateArray(data, key),
      },
      {
        key: ApplicationKeys.PERSONAL_DATA,
        label: i18n.tc('applicationColumns.personalData'),
      },
    ],
    [Domain.FI]: [
      {
        key: ApplicationKeys.CHANNEL,
        label: i18n.tc('applicationColumns.channel'),
      },
      {
        key: ApplicationKeys.FULL_NAME,
        label: i18n.tc('applicationColumns.fullName'),
      },
      {
        key: ApplicationKeys.CREATION_DATETIME,
        label: i18n.tc('applicationColumns.creationDatetime'),
        sorter: (data, key) => sortDateArray(data, key),
      },
      {
        key: ApplicationKeys.APPLICATION_NUMBER,
        label: i18n.tc('applicationColumns.applicationNumber'),
      },
      {
        key: ApplicationKeys.COPY,
        label: i18n.tc('applicationColumns.copy'),
      },
      {
        key: ApplicationKeys.CONTRACT_LINK,
        label: i18n.tc('applicationColumns.contractLink'),
      },
      {
        key: ApplicationKeys.STATUS,
        label: i18n.tc('applicationColumns.status'),
        sorter: (data) => sortStatusArray(data),
      },
      {
        key: ApplicationKeys.PRODUCT_NAME,
        label: i18n.tc('applicationColumns.productName'),
      },
      {
        key: ApplicationKeys.LOAN_AMOUNT,
        label: i18n.tc('applicationColumns.loanAmount'),
        sorter: (data, key) => sortNumberArray(data, key),
      },
      {
        key: ApplicationKeys.BRAND,
        label: i18n.tc('applicationColumns.brand'),
      },
      {
        key: ApplicationKeys.MODEL,
        label: i18n.tc('applicationColumns.model'),
      },
      {
        key: ApplicationKeys.REGISTRATION_NUMBER,
        label: i18n.tc('applicationColumns.registrationNumber'),
      },
    ],
    [Domain.LV]: [
      {
        key: ApplicationKeys.CHANNEL,
        label: i18n.tc('applicationColumns.channel'),
      },
      {
        key: ApplicationKeys.FULL_NAME,
        label: i18n.tc('applicationColumns.fullName'),
      },
      {
        key: ApplicationKeys.CREATION_DATETIME,
        label: i18n.tc('applicationColumns.creationDatetime'),
        sorter: (data, key) => sortDateArray(data, key),
      },
      {
        key: ApplicationKeys.APPLICATION_NUMBER,
        label: i18n.tc('applicationColumns.applicationNumber'),
      },
      {
        key: ApplicationKeys.STATUS,
        label: i18n.tc('applicationColumns.status'),
        sorter: (data) => sortStatusArray(data),
      },
      {
        key: ApplicationKeys.ID_DOCUMENT_TYPE,
        label: i18n.tc('applicationColumns.idDocumentType'),
      },
      {
        key: ApplicationKeys.FIRST_NAME,
        label: i18n.tc('applicationColumns.firstName'),
      },
      {
        key: ApplicationKeys.SURNAME,
        label: i18n.tc('applicationColumns.surname'),
      },
      {
        key: ApplicationKeys.PERSONAL_IDENTITY_CODE,
        label: i18n.tc('applicationColumns.personalIdentityCode'),
      },
      {
        key: ApplicationKeys.PRODUCT_NAME,
        label: i18n.tc('applicationColumns.productName'),
      },
      {
        key: ApplicationKeys.LOAN_AMOUNT,
        label: i18n.tc('applicationColumns.loanAmount'),
        sorter: (data, key) => sortNumberArray(data, key),
      },
      {
        key: ApplicationKeys.CONTRACT_NUMBER,
        label: i18n.tc('applicationColumns.contractNumber'),
      },
      {
        key: ApplicationKeys.PERSONAL_DATA,
        label: i18n.tc('applicationColumns.personalData'),
      },
    ],
    [Domain.LT]: [
      ...(isInternal
        ? [
          {
            key: ApplicationKeys.PARTNER,
            label: i18n.tc('applicationColumns.partner'),
          },
        ]
        : []),
      {
        key: ApplicationKeys.CHANNEL,
        label: i18n.tc('applicationColumns.channel'),
      },
      {
        key: ApplicationKeys.USERNAME,
        label: i18n.tc('applicationColumns.username'),
      },
      {
        key: ApplicationKeys.APPLICATION_NUMBER,
        label: i18n.tc('applicationColumns.applicationNumber'),
      },
      {
        key: ApplicationKeys.PERSONAL_DATA,
        label: i18n.tc('applicationColumns.personalData'),
        class: 'align-center',
      },
      {
        key: ApplicationKeys.FULL_NAME,
        label: i18n.tc('applicationColumns.fullName'),
      },
      {
        key: ApplicationKeys.CREATION_DATETIME,
        label: i18n.tc('applicationColumns.creationDatetime'),
        sorter: (data, key) => sortDateArray(data, key),
      },
      {
        key: ApplicationKeys.CONTRACT_LINK,
        label: i18n.tc('applicationColumns.contractLink'),
      },
      {
        key: ApplicationKeys.SEGMENT_NAME,
        label: i18n.tc('applicationColumns.segmentName'),
      },
      {
        key: ApplicationKeys.CONSENT_ID,
        label: i18n.tc('applicationColumns.consentId'),
      },
      {
        key: ApplicationKeys.ID_DOCUMENT_SCANNED,
        label: i18n.tc('applicationColumns.idDocumentScanned'),
      },
      {
        key: ApplicationKeys.CONSENT_METHOD_COPIED_FROM,
        label: i18n.tc('applicationColumns.consentMethodCopiedFrom'),
      },
      {
        key: ApplicationKeys.STATUS,
        label: i18n.tc('applicationColumns.status'),
        sorter: (data) => sortStatusArray(data),
      },
      {
        key: ApplicationKeys.REJECTION_REASONS,
        label: i18n.tc('applicationColumns.rejectionReasons'),
      },
      {
        key: ApplicationKeys.ID_DOCUMENT_TYPE,
        label: i18n.tc('applicationColumns.idDocumentType'),
      },
      {
        key: ApplicationKeys.EXTERNAL_ID,
        label: i18n.tc('applicationColumns.externalId'),
      },
      {
        key: ApplicationKeys.PERSONAL_IDENTITY_CODE,
        label: i18n.tc('applicationColumns.personalIdentityCode'),
      },
      {
        key: ApplicationKeys.PRODUCT_NAME,
        label: i18n.tc('applicationColumns.productName'),
      },
      {
        key: ApplicationKeys.GOODS_NAME,
        label: i18n.tc('applicationColumns.goodsName'),
      },
      {
        key: ApplicationKeys.LOAN_AMOUNT,
        label: i18n.tc('applicationColumns.loanAmount'),
        sorter: (data, key) => sortNumberArray(data, key),
      },
      {
        key: ApplicationKeys.CONTRACT_NUMBER,
        label: i18n.tc('applicationColumns.contractNumber'),
      },
      {
        key: ApplicationKeys.CONTRACT_STATUS,
        label: i18n.tc('applicationColumns.contractStatus'),
      },
      {
        key: ApplicationKeys.CONTRACT_SIGNING_METHOD,
        label: i18n.tc('applicationColumns.contractSigningMethod'),
      },
      {
        key: ApplicationKeys.CONTRACT_SIGNING_DATETIME,
        label: i18n.tc('applicationColumns.contractSigningDatetime'),
        sorter: (data, key) => sortDateArray(data, key),
      },
      {
        key: ApplicationKeys.SPOUSE_FIRSTNAME,
        label: i18n.tc('applicationColumns.spouseFirstname'),
      },
      {
        key: ApplicationKeys.SPOUSE_SURNAME,
        label: i18n.tc('applicationColumns.spouseSurname'),
      },
      {
        key: ApplicationKeys.SPOUSE_PERSONAL_IDENTITY_CODE,
        label: i18n.tc('applicationColumns.spousePersonalIdentityCode'),
      },
      {
        key: ApplicationKeys.SPOUSE_CONSENT_METHOD,
        label: i18n.tc('applicationColumns.spouseConsentMethod'),
      },
      {
        key: ApplicationKeys.SPOUSE_CONSENT_DATETIME,
        label: i18n.tc('applicationColumns.spouseConsentDatetime'),
        sorter: (data, key) => sortDateArray(data, key),
      },
      {
        key: ApplicationKeys.SPOUSE_CONSENT_METHOD_COPIED_FROM,
        label: i18n.tc('applicationColumns.spouseConsentMethodCopiedFrom'),
      },
      {
        key: ApplicationKeys.PARTNER_USER_ID,
      },
    ],
  };

  const typeColumns = {
    [ApplicationType.CONTRACT_CHANGE]: [
      {
        key: ApplicationKeys.CHANNEL,
        label: i18n.tc('applicationColumns.channel'),
      },
      {
        key: ApplicationKeys.PLANNED_SIGNING_DATE,
        label: i18n.tc('applicationColumns.plannedSigningDate'),
      },
      {
        key: ApplicationKeys.CONTRACT_NUMBER,
        label: i18n.tc('applicationColumns.contractNumber'),
      },
      {
        key: ApplicationKeys.CHANGE_TYPE_CODE,
        label: i18n.tc('applicationColumns.changeTypeCode'),
      },
    ],
    [ApplicationType.PROMISE_TO_PAY]: [
      {
        key: ApplicationKeys.CHANNEL,
        label: i18n.tc('applicationColumns.channel'),
      },
      {
        key: ApplicationKeys.PLANNED_SIGNING_DATE,
        label: i18n.tc('applicationColumns.plannedSigningDate'),
      },
      {
        key: ApplicationKeys.CONTRACT_NUMBER,
        label: i18n.tc('applicationColumns.contractNumber'),
      },
      {
        key: ApplicationKeys.STATUS,
        label: i18n.tc('applicationColumns.status'),
        sorter: (data) => sortStatusArray(data),
      },
    ],
  };

  const savedColumns = localStorage.selectedApplicationColumns;

  const savedColumnsKeys = savedColumns
    ? JSON.parse(savedColumns).map(savedColumn => savedColumn.key)
    : [];

  if (type) {
    return typeColumns[type];
  }

  if (forCsvExport) {
    return columns[getSelectedDomain()].filter((column) => !skipColumnsInCsv[getSelectedDomain()].includes(column.key));
  }

  return columns[getSelectedDomain()].map(column => {
    if (sortableKeys.includes(column.key)) {
      column.sortable = true;
    }
    if (noWrapKeys.includes(column.key)) {
      column.class = column.class ? `${column.class} f-nowrap` : 'f-nowrap';
    }

    return column;
  }).filter((column) => {
    if (showUnfilteredColumns || !savedColumnsKeys.length) {
      if (defaultColumns[getSelectedDomain()].includes(column.key)) {
        return true;
      }
    }

    if (!savedColumnsKeys.includes(ApplicationKeys.APPLICATION_NUMBER)) {
      savedColumnsKeys.push(ApplicationKeys.APPLICATION_NUMBER);
    }

    return savedColumnsKeys.includes(column.key);
  },
  ).filter((column) => !exportOnlyColumns[getSelectedDomain()].includes(column.key) );
};

const skipColumnsInCsv = {
  [Domain.LT]: [
    ApplicationKeys.CONTRACT_LINK,
    ApplicationKeys.EXTERNAL_ID,
    ApplicationKeys.PERSONAL_DATA,
  ],
  [Domain.FI]: [],
  [Domain.LV]: [],
  [Domain.EE]: [
    ApplicationKeys.EDIT,
    ApplicationKeys.PERSONAL_DATA,
  ],
};

const exportOnlyColumns = {
  [Domain.LT]: [
    ApplicationKeys.PERSONAL_IDENTITY_CODE,
    ApplicationKeys.CONSENT_METHOD_COPIED_FROM,
    ApplicationKeys.SPOUSE_CONSENT_METHOD_COPIED_FROM,
    ApplicationKeys.CONTRACT_SIGNING_METHOD,
    ApplicationKeys.EXTERNAL_ID,
    ApplicationKeys.CONTRACT_SIGNING_DATETIME,
    ApplicationKeys.SPOUSE_FIRSTNAME,
    ApplicationKeys.SPOUSE_SURNAME,
    ApplicationKeys.SPOUSE_PERSONAL_IDENTITY_CODE,
    ApplicationKeys.SPOUSE_CONSENT_METHOD,
    ApplicationKeys.SPOUSE_CONSENT_DATETIME,
    ApplicationKeys.CONTRACT_NUMBER,
    ApplicationKeys.PARTNER_USER_ID,
    ApplicationKeys.REJECTION_REASONS,
  ],
  [Domain.FI]: [],
  [Domain.LV]: [
    ApplicationKeys.PERSONAL_IDENTITY_CODE,
    ApplicationKeys.FIRST_NAME,
    ApplicationKeys.SURNAME,
  ],
  [Domain.EE]: [
    ApplicationKeys.FIRST_NAME,
    ApplicationKeys.SURNAME,
  ],
};

const sortableKeys = [
  ApplicationKeys.CHANNEL,
  ApplicationKeys.USERNAME,
  ApplicationKeys.CREATION_DATETIME,
  ApplicationKeys.APPLICATION_NUMBER,
  ApplicationKeys.STATUS,
  ApplicationKeys.FIRST_NAME,
  ApplicationKeys.SURNAME,
  ApplicationKeys.PERSONAL_IDENTITY_CODE,
  ApplicationKeys.PRODUCT_NAME,
  ApplicationKeys.LOAN_AMOUNT,
  ApplicationKeys.SELF_FINANCING_AMOUNT,
  ApplicationKeys.GOODS_PRICE,
  ApplicationKeys.GOODS_NAME,
  ApplicationKeys.CONTRACT_SIGNING_DATETIME,
  ApplicationKeys.FULL_NAME,
  ApplicationKeys.CONTRACT_LINK,
  ApplicationKeys.BRAND,
  ApplicationKeys.MODEL,
  ApplicationKeys.REGISTRATION_NUMBER,
  ApplicationKeys.ID_DOCUMENT_TYPE,
  ApplicationKeys.PARTNER,
  ApplicationKeys.SEGMENT_NAME,
  ApplicationKeys.CONSENT_ID,
  ApplicationKeys.ID_DOCUMENT_SCANNED,
  ApplicationKeys.CONSENT_METHOD_COPIED_FROM,
  ApplicationKeys.REJECTION_REASONS,
  ApplicationKeys.CONTRACT_STATUS,
  ApplicationKeys.CONTRACT_SIGNING_METHOD,
  ApplicationKeys.SPOUSE_FIRSTNAME,
  ApplicationKeys.SPOUSE_SURNAME,
  ApplicationKeys.SPOUSE_PERSONAL_IDENTITY_CODE,
  ApplicationKeys.SPOUSE_CONSENT_METHOD,
  ApplicationKeys.SPOUSE_CONSENT_DATETIME,
  ApplicationKeys.SPOUSE_CONSENT_METHOD_COPIED_FROM,
];

const noWrapKeys = [
  ApplicationKeys.CHANNEL,
  ApplicationKeys.USERNAME,
  ApplicationKeys.CREATION_DATETIME,
  ApplicationKeys.APPLICATION_NUMBER,
  ApplicationKeys.STATUS,
  ApplicationKeys.PRODUCT_NAME,
  ApplicationKeys.LOAN_AMOUNT,
  ApplicationKeys.CALCULATED_BONUS_AMOUNT,
  ApplicationKeys.PERSONAL_DATA,
  ApplicationKeys.FULL_NAME,
  ApplicationKeys.CONTRACT_LINK,
  ApplicationKeys.BRAND,
  ApplicationKeys.MODEL,
  ApplicationKeys.REGISTRATION_NUMBER,
  ApplicationKeys.ID_DOCUMENT_TYPE,
  ApplicationKeys.PARTNER,
  ApplicationKeys.SEGMENT_NAME,
  ApplicationKeys.CONSENT_ID,
  ApplicationKeys.ID_DOCUMENT_SCANNED,
  ApplicationKeys.CONSENT_METHOD_COPIED_FROM,
  ApplicationKeys.REJECTION_REASONS,
  ApplicationKeys.EXTERNAL_ID,
  ApplicationKeys.CONTRACT_STATUS,
  ApplicationKeys.CONTRACT_SIGNING_METHOD,
  ApplicationKeys.SPOUSE_FIRSTNAME,
  ApplicationKeys.SPOUSE_SURNAME,
  ApplicationKeys.SPOUSE_PERSONAL_IDENTITY_CODE,
  ApplicationKeys.SPOUSE_CONSENT_METHOD,
  ApplicationKeys.SPOUSE_CONSENT_DATETIME,
  ApplicationKeys.SPOUSE_CONSENT_METHOD_COPIED_FROM,
];

const defaultColumns = {
  [Domain.EE]: [
    ApplicationKeys.EDIT,
    ApplicationKeys.CHANNEL,
    ApplicationKeys.USERNAME,
    ApplicationKeys.CREATION_DATETIME,
    ApplicationKeys.STATUS,
    ApplicationKeys.FIRST_NAME,
    ApplicationKeys.SURNAME,
    ApplicationKeys.PERSONAL_IDENTITY_CODE,
    ApplicationKeys.PRODUCT_NAME,
    ApplicationKeys.LOAN_AMOUNT,
    ApplicationKeys.SELF_FINANCING_AMOUNT,
    ApplicationKeys.GOODS_PRICE,
    ApplicationKeys.GOODS_NAME,
    ApplicationKeys.CONTRACT_NUMBER,
    ApplicationKeys.CONTRACT_SIGNING_DATETIME,
    ApplicationKeys.CALCULATED_BONUS_AMOUNT,
    ApplicationKeys.PERSONAL_DATA,
    ApplicationKeys.COMMISSION_AMOUNT,
  ],
  [Domain.FI]: [
    ApplicationKeys.CHANNEL,
    ApplicationKeys.COPY,
    ApplicationKeys.FULL_NAME,
    ApplicationKeys.CREATION_DATETIME,
    ApplicationKeys.CONTRACT_LINK,
    ApplicationKeys.STATUS,
    ApplicationKeys.PERSONAL_IDENTITY_CODE,
    ApplicationKeys.PRODUCT_NAME,
    ApplicationKeys.LOAN_AMOUNT,
    ApplicationKeys.BRAND,
    ApplicationKeys.MODEL,
    ApplicationKeys.REGISTRATION_NUMBER,
  ],
  [Domain.LV]: [
    ApplicationKeys.CHANNEL,
    ApplicationKeys.FULL_NAME,
    ApplicationKeys.CREATION_DATETIME,
    ApplicationKeys.STATUS,
    ApplicationKeys.ID_DOCUMENT_TYPE,
    ApplicationKeys.FIRST_NAME,
    ApplicationKeys.SURNAME,
    ApplicationKeys.PERSONAL_IDENTITY_CODE,
    ApplicationKeys.PRODUCT_NAME,
    ApplicationKeys.LOAN_AMOUNT,
    ApplicationKeys.PERSONAL_DATA,
  ],
  [Domain.LT]: [
    ApplicationKeys.PARTNER,
    ApplicationKeys.CHANNEL,
    ApplicationKeys.USERNAME,
    ApplicationKeys.FULL_NAME,
    ApplicationKeys.CREATION_DATETIME,
    ApplicationKeys.CONTRACT_LINK,
    ApplicationKeys.SEGMENT_NAME,
    ApplicationKeys.CONSENT_ID,
    ApplicationKeys.ID_DOCUMENT_SCANNED,
    ApplicationKeys.CONSENT_METHOD_COPIED_FROM,
    ApplicationKeys.STATUS,
    ApplicationKeys.REJECTION_REASONS,
    ApplicationKeys.ID_DOCUMENT_TYPE,
    ApplicationKeys.EXTERNAL_ID,
    ApplicationKeys.PERSONAL_IDENTITY_CODE,
    ApplicationKeys.PRODUCT_NAME,
    ApplicationKeys.GOODS_NAME,
    ApplicationKeys.LOAN_AMOUNT,
    ApplicationKeys.CONTRACT_NUMBER,
    ApplicationKeys.CONTRACT_STATUS,
    ApplicationKeys.CONTRACT_SIGNING_METHOD,
    ApplicationKeys.CONTRACT_SIGNING_DATETIME,
    ApplicationKeys.SPOUSE_FIRSTNAME,
    ApplicationKeys.SPOUSE_SURNAME,
    ApplicationKeys.SPOUSE_PERSONAL_IDENTITY_CODE,
    ApplicationKeys.SPOUSE_CONSENT_METHOD,
    ApplicationKeys.SPOUSE_CONSENT_DATETIME,
    ApplicationKeys.SPOUSE_CONSENT_METHOD_COPIED_FROM,
    ApplicationKeys.PERSONAL_DATA,
  ],
};
